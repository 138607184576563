import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';

type InvitedOnboardingState = {
  data: Record<string, any>;
  setData: (newData: Record<string, any>) => void;
};

/** useInvitedOnboardingStore manages onboarding flow state with zustand
 * useInvitedOnboardingStore is a hook that can be used to get onboarding state from within any component.
 * components will re-render on changes to state
 * @returns {InvitedOnboardingState} as a zustand store
 */

export const useInvitedOnboardingStore = create(
  persist<
    InvitedOnboardingState,
    SetState<InvitedOnboardingState>,
    GetState<InvitedOnboardingState>,
    StoreApiWithPersist<InvitedOnboardingState>
  >(
    (set) => ({
      data: {},
      setData: (data) => set({ data }),
    }),
    {
      name: 'INVITED_ONBOARDING_STORE',
      getStorage: () => sessionStorage,
    }
  )
);
