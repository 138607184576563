import { ApolloError, useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import Cookies from 'js-cookie';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {
  AuthSession,
  store,
  useSession,
  useSignIn,
  useSignOut,
} from '@updater/auth';
import { useAuthIdentityQuery } from '@updater/consumer-graph';
import { TrackEventUser } from '@updater/ui-tracker';
import { useNativeAppCommand } from 'context/consumer-web-bridge';
import { getUserInfo, getUserInfoVariables } from 'types/generated/getUserInfo';
import { useServerProps } from 'utils/ServerProps';
import { GetUserInfoQuery } from 'utils/queries';
import { useRootTracker } from 'utils/tracker';

export const AuthMigrationProvider: FC<PropsWithChildren> = (props) => {
  const { session } = useSession();
  const [signInRequest] = useSignIn();

  const identityQuery = useAuthIdentityQuery({ skip: !session });

  return (
    <CredentialProvider>
      <DeprecatedAuthProvider
        {...props}
        // always true with persistAuth() above
        isInitialized
        loading={signInRequest.status === 'pending'}
        session={session}
        currentUserId={identityQuery.data?.user?.id}
        currentUserUUID={
          identityQuery.data?.user?.__typename === 'User'
            ? identityQuery.data?.user?.uuid
            : undefined
        }
      />
    </CredentialProvider>
  );
};

export function useAuthMigrationSignOut({ redirect } = { redirect: true }) {
  const nativeApp = useNativeAppCommand();
  const [_signOutRequest, signOut] = useSignOut();

  return useCallback(
    async (info?: string) => {
      deleteSessionLocals();
      deleteSessionCookies();
      // move to "web app logout" with sufficient adoption of native app release
      try {
        await signOut();
      } catch (e) {
        // logout failed for some reason, logging out anyway
      }

      // native app logout
      if (nativeApp.active) {
        nativeApp.logout();
      }
      // web app logout
      // intentionally not using Next/Router so that this forces a full page refresh to purge any in memory stores.
      else if (
        // TODO:  remove, all of this will be removed, but particularly this part
        redirect
      ) {
        window.location.href = `/${
          info ? `?info=${encodeURIComponent(info)}` : ''
        }`;
      }
    },
    [redirect, signOut, nativeApp]
  );
}

type DeprecatedAuthProviderProps = {
  isInitialized: boolean;
  loading: boolean;
  session?: AuthSession;
  currentUserId?: string;
  currentUserUUID?: string;
};

/**
 * @deprecated this and everything below this
 */
export interface IAuthContext {
  /**
   * Logs the user in
   * @param email User's email
   * @param password User's password
   */
  login(email: string, password: string): Promise<void>;

  /**
   * Logs the user out. Will do nothing if the user
   * is already logged out
   */
  logout(info?: string): void;

  /**
   * True if the user is currently logged in
   */
  isLoggedIn: boolean;
  /**
   * True if a login or logout is in-progress
   * and we're waiting for the response
   */
  isLoading: boolean;
  /**
   * True once we have booted the component
   * and checked the current logged in status
   */
  isInitialized: boolean;
  /**
   * The current user's `id`, or `null`
   * if the user is not logged in
   */
  currentUserId?: string;
  /**
   * The current user's `uuid`, or `null`
   * if the user is not logged in
   */
  currentUserUUID?: string;
  /**
   * setup the current session
   * used after signup
   */
  setSession: (params: SetSessionParams) => void;
  /**
   * True if a login call returns an error
   */
  isError: boolean;
  /**
   * Contains Login Error
   */
  error: ApolloError | undefined;
}

export type Notification = {
  channel: string;
  messageType: string;
  subscribe: boolean;
};

export const AuthContext = React.createContext<IAuthContext>(
  {} as IAuthContext
);

/**
 * @deprecated
 */
export type ICredentials = {
  accessToken?: string;
  client?: string;
  uid?: string;
  logout?: (info?: string) => void;
};

/**
 * @deprecated
 */
type CredentialContextValue = [
  ICredentials,
  React.Dispatch<React.SetStateAction<ICredentials>>
];

/**
 * @deprecated
 */
const CredentialContext = React.createContext<CredentialContextValue>([
  {},
  () => {},
]);

/**
 * @deprecated
 */
export const CredentialProvider: React.FC<
  PropsWithChildren<{ initialState?: ICredentials }>
> = ({ children }) => {
  const { session } = useSession();

  const credentialState = useMemo(() => {
    return [
      {
        ...session,
        accessToken: session?.['access-token'],
        logout: () =>
          console.error('Deprecated call to CredentialProvider[state].logout'),
      },
      (_: ICredentials) =>
        console.error('Deprecated call to CredentialProvider.setState'),
    ] as CredentialContextValue;
  }, [session]);

  return (
    <CredentialContext.Provider value={credentialState}>
      {children}
    </CredentialContext.Provider>
  );
};

/**
 * @deprecated
 */
export const useCredentials = () => useContext(CredentialContext);

/**
 * @deprecated
 */
type SetSessionParams = {
  userId: string;
  userUUID: string;
  session: { accessToken: string; client: string; uid: string };
};

/**
 * @deprecated
 */
const TrackIdentity: React.FC<{ identity: TrackEventUser }> = ({
  identity,
}) => {
  const { env } = useServerProps();
  const tracker = useRootTracker();
  const { data } = useQuery<getUserInfo, getUserInfoVariables>(
    GetUserInfoQuery,
    {
      variables: {
        id: identity.moverId,
      },
      skip: identity.moverId === null,
    }
  );

  const user = data?.getUser?.user;

  useEffect(() => {
    tracker.identify({
      ...identity,
      moveId: user?.currentMove?.id || '',
    });
    window?.FS?.(identity.uuid);
    window?.FS?.identify(identity.uuid, {
      uid: identity.uuid,
      email: identity.email,
      application: 'consumer',
      appVersion: global.__VERSION_STRING__,
      environment: env,
      displayName: `${user?.firstName || ''} ${user?.lastName || ''}`,
      residentOnboardingEnabled: user?.currentMove?.residentOnboardingEnabled,
    });
    datadogRum.setUser({
      id: identity.uuid,
      uuid: identity.uuid,
      residentOnboardingEnabled: user?.currentMove?.residentOnboardingEnabled,
    });
  }, [identity, tracker, env, data]);

  useEffect(() => {
    window?.FS?.setUserVars({
      ownership: data?.getUser?.user?.currentMove?.toAddress?.ownership,
    });
  }, [data?.getUser?.user?.currentMove?.toAddress?.ownership, env]);

  return null;
};

/**
 * @deprecated
 */
const TrackAnon: React.FC<Record<string, unknown>> = () => {
  const tracker = useRootTracker();
  const { env } = useServerProps();
  useEffect(() => {
    tracker.identify(null);
    if (!['functional-testing', 'development'].includes(env)) {
      window.FS?.();
    }
  }, [tracker, env]);
  return null;
};

export const ROOT_DOMAIN = 'updater.com';

const deleteSessionLocals = () => {
  const deviceId = localStorage.getItem('DEVICE_IDS');

  localStorage.clear();
  sessionStorage.clear();

  if (deviceId) {
    localStorage.setItem('DEVICE_IDS', deviceId);
  }
};

const deleteSessionCookies = () => {
  Cookies.remove('access-token', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('client', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('uid', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('currentUserId', { path: '/', domain: ROOT_DOMAIN });
  Cookies.remove('currentUserUUID', { path: '/', domain: ROOT_DOMAIN });
};

/**
 * @deprecated
 */
const DeprecatedAuthProvider: FC<
  PropsWithChildren<DeprecatedAuthProviderProps>
> = ({ children, session, isInitialized, currentUserId, currentUserUUID }) => {
  const [signInRequest, signIn] = useSignIn();

  const login = useCallback(
    async (email: string, password: string) => {
      await signIn({ type: 'private', input: { email, password } });
    },
    [signIn]
  );
  const logout = useAuthMigrationSignOut();

  const setSession = useCallback((props: SetSessionParams) => {
    store.setSession({
      mode: 'authenticated',
      session: {
        'access-token': props.session.accessToken,
        client: props.session.client,
        uid: props.session.uid,
      },
    });
  }, []);

  const contextVal = useMemo(
    (): IAuthContext => ({
      login,
      logout,
      isLoggedIn: currentUserId !== undefined,
      isLoading: signInRequest.status === 'pending',
      currentUserId,
      currentUserUUID,
      isInitialized,
      setSession,
      // @ts-expect-error: it's there
      isError: signInRequest.error !== undefined,
      // @ts-expect-error: it's there
      error: signInRequest.error,
    }),
    [
      currentUserId,
      currentUserUUID,
      setSession,
      isInitialized,
      login,
      logout,
      signInRequest,
    ]
  );

  const identity = useMemo(
    () =>
      currentUserId && currentUserUUID
        ? {
            moverId: currentUserId,
            uuid: currentUserUUID,
            email: session?.uid,
          }
        : null,
    [currentUserUUID, currentUserId, session?.uid]
  );

  return (
    <AuthContext.Provider value={contextVal}>
      <>
        {identity ? <TrackIdentity identity={identity} /> : <TrackAnon />}
        {children}
      </>
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => useContext(AuthContext);

export const setAuthSessionDataFromCookies = () => {};
