import { datadogRum } from '@datadog/browser-rum';
import Axios, { AxiosInstance } from 'axios';
import { SWRConfig } from 'swr';
import React, {
  useContext,
  useMemo,
  useCallback,
  PropsWithChildren,
} from 'react';

import { useSession, useSignOut } from '@updater/auth';
import { useServerProps } from 'utils/ServerProps';
import { useAuthMigrationSignOut, useCredentials } from '../auth';

export interface IRESTClientContext {
  axios: AxiosInstance;
}

const RESTClientContext = React.createContext<IRESTClientContext>(
  {} as IRESTClientContext
);

export const RESTClientProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { session } = useSession();
  // TODO: this nonsense will be removed, this is an incredibly bad patch
  //       to handle distance and indirection between request handling
  //       password resets, and expiring tokens.
  const signOut = useAuthMigrationSignOut({ redirect: false });

  const { apiBaseUrl } = useServerProps();
  const context = useMemo(() => {
    const axios = Axios.create({
      baseURL: apiBaseUrl,
      headers: {
        name: 'consumer-app',
        app: 'mover',
        'X-Subdomain-Redirect-Disable': 'true',
        ...session,
      },
    });

    return { axios };
  }, [apiBaseUrl, session]);

  context.axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response.request;
      if (status === 401) {
        signOut();
      }
      if (status === 403) {
        // For now we're not logging users out on this case.
        // This is because we believe this case is actually an api error
        // and ideally should be handled where it occurs. For now we log
        // the error so we can track this.
        datadogRum.addError(
          new Error(`403 FORBIDDEN NETWORK RESPONSE FOR REST REQUEST VIA SWR`),
          { uid: session?.uid }
        );
      }

      // Rereaise the error
      return Promise.reject(error);
    }
  );

  const swrFetcher = useCallback(
    async (...parts: string[]) => {
      return context.axios.get(parts.join('')).then((res) => res?.data);
    },
    [context.axios]
  );

  return (
    <RESTClientContext.Provider value={context}>
      <SWRConfig
        value={{
          fetcher: swrFetcher,
          revalidateOnFocus: false,
          errorRetryCount: 3,
        }}
      >
        {children}
      </SWRConfig>
    </RESTClientContext.Provider>
  );
};

export const useRESTClient = () => useContext(RESTClientContext);
