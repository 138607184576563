import { useAuth } from 'context';
import { AuthOverlay } from 'components/auth-overlay';
import { SpinnerFullPage } from '@updater/ui-uds';
import { useIsAuthenticated, useSession } from '@updater/auth';
import { PropsWithChildren } from 'react';

export const LoginRequired: React.FC<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  // TODO: nix every use of currentUserUUID before removing
  const { currentUserUUID } = useAuth();

  if (!isAuthenticated) {
    return <AuthOverlay title="Welcome back!" />;
  }

  if (currentUserUUID == null) {
    return <SpinnerFullPage />;
  }

  return <>{children}</>;
};
