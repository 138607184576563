import { useBridgeRelay } from '@updater/native-web';
import { VFC } from 'react';
import { useAuth } from '../auth';
import { useRouteCapture } from './use-route-capture';
import { useRouterEventRelay } from './use-router-event-relay';

/**
 * The container binding this app to updater/{web|native}-bridge
 */
export const ConsumerNativeWebBridge: VFC = () => {
  const auth = useAuth();

  useRouteCapture();
  useRouterEventRelay();

  // TODO import from @updater/native-web
  useBridgeRelay(() => {
    if (auth.isInitialized && !auth.isLoading) {
      return {
        type: 'authentication:status',
        authentication: { authenticated: auth.isLoggedIn },
        source: 'consumer-app:relay',
      };
    }
  }, [auth.isLoggedIn]);

  // announce that we're done
  useBridgeRelay(() => ({ type: `bridge:ready` }), []);

  return null;
};
