// Navbar
export const DESKTOP_NAVBAR_WIDTH = '188px';
export const TABLET_NAVBAR_HEIGHT = 104;
export const MOBILE_NAVBAR_HEIGHT = 64;
export const NAVBAR_HEIGHT = [
  `${MOBILE_NAVBAR_HEIGHT}px`,
  `${MOBILE_NAVBAR_HEIGHT}px`,
  `${TABLET_NAVBAR_HEIGHT}px`,
  '100vh',
];
