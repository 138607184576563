import { css, Global } from '@emotion/react';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { FC, useEffect } from 'react';

import { NativeWebServerProps, NativeWebViewport } from '@updater/native-web';
import { ThemeProvider } from '@updater/ui-uds';
import { ErrorBoundary } from 'components/error-boundary';
import LivePersonChatScript from 'components/live-person-chat/LivePersonChatScript';
import { LoginRequired } from 'components/login-required';
import { NavigationLayout } from 'components/navigation-layout';
import {
  AuthMigrationProvider,
  ConsumerNativeWebBridge,
  RESTClientProvider,
  persistAuth,
} from 'context';
import { ApolloClientSetup } from 'context/apollo-client';
import {
  FullstoryProvider,
  GoogleAnalyticsProvider,
  GoogleAnalyticsTracking,
  UIWidgetsProvider,
  UpdaterAuthProvider,
} from 'providers';
import { ConsumerAppNextPage } from 'types';
import { ServerProps, ServerVariables } from 'utils/ServerProps';
import { RootTrackerProvider } from 'utils/tracker';
import { BlockServerRender } from 'components/block-server-render';
import { GrowthbookAppWrapper } from 'context/growthbook';
import { Work_Sans } from 'next/font/google';

const globalStyles = css`
  body {
    margin: 0;
  }
`;

// enable auth persistence
persistAuth();

type ConsumerAppAppProps = Omit<AppProps, 'Component'> & {
  Component: ConsumerAppNextPage;
  serverVariables: ServerVariables;
};

const WorkSans = Work_Sans({
  subsets: ['latin', 'latin-ext'],
  weight: ['300', '400', '600'],
  display: 'swap',
  variable: '--font-work-sans',
});

const ConsumerAppWrapper = ({
  Component,
  pageProps,
  serverVariables,
}: ConsumerAppAppProps) => {
  useEffect(() => {
    // eslint-disable-next-line no-console, no-underscore-dangle
    console.log(`appVersion: ${global.__VERSION_STRING__}`);
  }, []);
  const componentWithProvider = Component.provider ? (
    <Component.provider>
      <Component {...pageProps} />
    </Component.provider>
  ) : (
    <Component {...pageProps} />
  );
  return (
    <main className={WorkSans.variable}>
      <ThemeProvider selfHostedFonts>
        <NativeWebServerProps.Provider value={serverVariables.webview}>
          <ServerProps initialServerVariables={serverVariables}>
            <Head>
              <title>Updater</title>
              <link rel="shortcut icon" href="/favicon.ico" />
            </Head>

            <NativeWebViewport as={Head as FC} />

            <FullstoryProvider env={serverVariables.env} />
            <GoogleAnalyticsProvider
              measurementId={serverVariables.googleAnalytics.measurementId}
            />
            <UpdaterAuthProvider>
              <ApolloClientSetup>
                <RESTClientProvider>
                  <RootTrackerProvider>
                    <AuthMigrationProvider>
                      <UIWidgetsProvider>
                        <Global styles={globalStyles} />

                        <ConsumerNativeWebBridge />

                        <GoogleAnalyticsTracking />

                        <LivePersonChatScript />

                        <ErrorBoundary>
                          <GrowthbookAppWrapper>
                            {Component.allowUnauthorized ? (
                              <NavigationLayout>
                                {componentWithProvider}
                              </NavigationLayout>
                            ) : (
                              <BlockServerRender>
                                <LoginRequired>
                                  <NavigationLayout>
                                    {componentWithProvider}
                                  </NavigationLayout>
                                </LoginRequired>
                              </BlockServerRender>
                            )}
                          </GrowthbookAppWrapper>
                        </ErrorBoundary>
                      </UIWidgetsProvider>
                    </AuthMigrationProvider>
                  </RootTrackerProvider>
                </RESTClientProvider>
              </ApolloClientSetup>
            </UpdaterAuthProvider>
          </ServerProps>
        </NativeWebServerProps.Provider>
      </ThemeProvider>
    </main>
  );
};

ConsumerAppWrapper.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const envSuffix =
    ['prod', 'staging'].includes(process.env.APPLICATION_ENVIRONMENT ?? '') &&
    process.env.POD_NAME?.includes('primary') === false
      ? '-canary'
      : '';
  const props = {
    ...appProps,
    serverVariables: {
      apiBaseUrl: process.env.API_BASE_URL,
      env: `${process.env.APPLICATION_ENVIRONMENT}${envSuffix}`,
      googlePlacesAPIKey: process.env.GOOGLE_PLACES_API_KEY,
      googleAnalytics: {
        measurementId: process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID,
        streamId: process.env.GOOGLE_ANALYTICS_STREAM_ID,
      },
      growthbookAPIHost: process.env.GROWTHBOOK_API_HOST,
      growthbookSDKKey: process.env.GROWTHBOOK_SDK_KEY,
      eventStream: {
        url: process.env.EVENT_STREAM_URL,
        batchUrl: process.env.EVENT_STREAM_BATCH_URL,
        token: process.env.EVENT_STREAM_TOKEN,
      },
      stripeClientKey: process.env.STRIPE_CLIENT_KEY,
      sureStripeClientKey: process.env.SURE_STRIPE_CLIENT_KEY,
      dollyBaseUrl: process.env.DOLLY_BASE_URL,
      sureApi: {
        url: process.env.SURE_API,
        token: process.env.SURE_API_TOKEN,
      },
      tviTokenServiceUrl: process.env.TVI_TOKEN_SERVICE_URL,
      leasingDeskUrl: process.env.LEASING_DESK_URL,
      webview: {
        useragent: appContext.ctx.req?.headers['user-agent'],
        simulated: process.env.SIMULATE_WEBVIEW,
      },
      tokenex: {
        scriptSrc: process.env.TOKENEX_SCRIPT_SRC,
        scriptIntegrity: process.env.TOKENEX_SCRIPT_INTEGRITY,
        iFrameTimeoutInSeconds: parseInt(
          process.env.TOKENEX_TOKENIZE_IFRAME_TIMEOUT_S ?? ''
        ),
        // TODO: remove insurance below once tokenex iframes are implemented
        insurance: {
          vaultId: process.env.TOKENEX_VAULT_ID_INSURANCE,
          apiKey: process.env.TOKENEX_API_KEY_INSURANCE,
        },
        apiUrl: process.env.TOKENEX_API_URL,
      },
    },
  };
  return props;
};

export default ConsumerAppWrapper;
