import { FC } from 'react';
import Script from 'next/script';

export const GoogleAnalyticsProvider: FC<{ measurementId: string }> = ({
  measurementId,
}) => (
  <>
    <Script
      type="text/javascript"
      src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
    />
    <Script
      id="googleAnalyticsInit"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${measurementId}');
        `,
      }}
    />
  </>
);
