import { useDataForCards } from './use-data-for-cards';

export const useItemsByStatus = (status: string) => {
  const { loading, error, data } = useDataForCards();

  if (!loading && error) {
    return [];
  }

  if (!loading && data) {
    const { items } = data;

    return items.filter((item) => item.status === status);
  }

  return [];
};
