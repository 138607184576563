import { AddressTypeaheadProvider } from '@updater/ui-widgets';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useServerProps } from 'utils/ServerProps';

export const UIWidgetsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const props = useServerProps();

  return (
    <AddressTypeaheadProvider
      // if it were an inteligent provider we wouldn't have to memoize what
      // should be a prop and not a raw object, but here we are saving some
      // render cycles on behalf of the address widget
      value={useMemo(
        () => ({ GOOGLE_PLACES_API_KEY: props.googlePlacesAPIKey }),
        [props.googlePlacesAPIKey]
      )}
    >
      {children}
    </AddressTypeaheadProvider>
  );
};
