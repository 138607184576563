import { useMemo } from 'react';
import { claimedPromotionFragment } from '../../../types/generated/claimedPromotionFragment';
import { dealsPromotionFragment } from '../../../types/generated/dealsPromotionFragment';
import { getPromotions } from '../../../types/generated/getPromotions';

// TODO: have the backend return one list where these are already merged, this will cleanup the frontend x100
export type MergedPromotion = dealsPromotionFragment & {
  claim?: Partial<claimedPromotionFragment>;
};

export const useMergedPromotions = (
  queryData: getPromotions | undefined
): Array<MergedPromotion> =>
  useMemo(() => {
    const map: Record<string, MergedPromotion> = {};

    // first add claimed promotions
    queryData?.claimedPromotions?.claimedPromotions?.forEach(
      (claimedPromotion) => {
        const { promotion, ...claim } = claimedPromotion ?? {};
        const id = promotion?.id;

        if (id && promotion) {
          map[id] = { ...promotion, claim };
        }
      }
    );

    // then add eligible promotions, if the promotion id is found in both these will overwrite
    queryData?.eligiblePromotions?.eligiblePromotions?.forEach((promotion) => {
      const id = promotion?.promotion?.id;

      if (id && promotion?.promotion && !map[id]) {
        map[id] = promotion?.promotion;
      }
    });

    return Object.values(map);
  }, [
    queryData?.claimedPromotions?.claimedPromotions,
    queryData?.eligiblePromotions?.eligiblePromotions,
  ]);
