import { useRouter } from 'next/router';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  SignupMover,
  SignupMoverVariables,
  SignupMover_signupMover,
} from 'types/generated/SignupMover';
import { useInvitedOnboardingStore } from 'flows/core/store';
import { useAuth } from 'context';
import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import {
  InviteByToken,
  InviteByTokenVariables,
  InviteByToken_inviteByToken_invite,
  InviteByToken_inviteByToken_invite_fromAddress,
  InviteByToken_inviteByToken_invite_toAddress,
} from 'types/generated/InviteByToken';
import { InviteQuery } from 'flows/core/components/onboarding/common/queries';
import { useToast } from '@updater/ui-uds';
import { signupMover } from './queries';
import { FormData } from './types';
import { AddressOwnership } from 'types/generated/globalTypes';
import { useFeature } from '@growthbook/growthbook-react';

const checkIsIOS = (): boolean => {
  const userAgent = window.navigator.userAgent || navigator.userAgent || '';

  const isIOS =
    /iPad|iPhone|iPod/.test(userAgent) ||
    // iPad on iOS 13 detection
    (userAgent.includes('Mac') && 'ontouchend' in document);

  return isIOS;
};

const checkIsAndroid = (): boolean => {
  const userAgent = window.navigator.userAgent || navigator.userAgent || '';

  const ua = userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1; // && ua.indexOf("mobile");

  return isAndroid;
};

export const useInviteToken = (): string | undefined => {
  const router = useRouter();
  return useMemo(() => {
    if (!router?.isReady) return undefined;
    return Array.isArray(router.query.inviteToken)
      ? router.query.inviteToken[0]
      : router.query.inviteToken;
  }, [router?.isReady, router?.query]);
};

export const useReferralCode = (inviteToken?: string): string | null => {
  const router = useRouter();

  return useMemo(() => {
    if (inviteToken) return null;
    if (!router?.isReady) return null;
    if (!router.query.referralCode) return 'd2c';

    return Array.isArray(router.query.referralCode)
      ? router.query.referralCode[0]
      : router.query.referralCode;
  }, [router?.isReady, router?.query, inviteToken]);
};

export const useInvite = () => {
  const inviteToken = useInviteToken();
  const result = useQuery<InviteByToken, InviteByTokenVariables>(InviteQuery, {
    variables: {
      token: inviteToken || '',
    },
    skip: !inviteToken,
  });
  return result;
};

export const useNavigateAfterOnboardingSubmit = () => {
  const router = useRouter();
  const { trackEvent } = useTracking<TrackEventInput<unknown>>();

  return useCallback(
    async (signUpMoverData: SignupMover_signupMover) => {
      const residentOnboardingEnabled =
        signUpMoverData?.mover?.currentMove?.residentOnboardingEnabled;

      const location = residentOnboardingEnabled
        ? '/welcome'
        : '/welcome-start';

      trackEvent({
        object: 'sign_up_redirect',
        pageName: 'sign-up',
        verb: 'tracked',
        details: {
          residentOnboardingEnabled,
          location,
        },
      });
      router.push(location);
    },
    [router, trackEvent]
  );
};

export const useOnboardingSubmit = () => {
  const [signup, { data, called, loading, error: apolloError }] = useMutation<
    SignupMover,
    SignupMoverVariables
  >(signupMover);
  const [smsCheckState, setSmsCheckState] = useState(false);
  const [succesful, setSuccesful] = useState(false);
  const navigateAfterSubmit = useNavigateAfterOnboardingSubmit();
  const toast = useToast();
  const { setSession, isLoggedIn } = useAuth();
  const inviteToken = useInviteToken();
  const referralCode = useReferralCode(inviteToken);
  const { trackEvent } = useTracking<TrackEventInput<unknown>>();

  useEffect(() => {
    const handleError = (error?: Error) => {
      const message = error?.message || 'There was an error saving your info';
      trackEvent({
        object: 'signup-form',
        pageName: 'submit',
        verb: 'errored',
        details: {
          error,
          message,
        },
      });
      toast({
        title: message,
        status: 'error',
      });
    };
    if (!called || loading) return;
    if (apolloError) {
      handleError(new Error(apolloError.message));
      return;
    }

    try {
      if (
        !data ||
        !data.signupMover ||
        !data.signupMover.mover ||
        !data.signupMover.session
      ) {
        handleError(new Error('There was an error saving your info'));
        return;
      }

      const {
        mover: { uuid, id },
        session,
      } = data.signupMover;

      useInvitedOnboardingStore.persist.clearStorage();

      setSession({
        session,
        userId: id,
        userUUID: uuid,
      });

      trackEvent({
        object: 'signup-form',
        pageName: 'submit',
        verb: 'submitted',
      });
      setSuccesful(true);
    } catch (e: any) {
      handleError(e);
    }
  }, [apolloError, called, data, loading, setSession, toast, trackEvent]);

  useEffect(() => {
    if (!data?.signupMover || !isLoggedIn) return;

    trackEvent({
      object: 'sms-cta',
      pageName: 'submit',
      verb: 'selected',
      details: {
        smsCheckState,
        phoneCompleted: data.signupMover.mover?.phone,
      },
    });

    navigateAfterSubmit(data.signupMover);
  }, [isLoggedIn, smsCheckState, data, trackEvent, navigateAfterSubmit]);

  const submitOnboarding = useCallback(
    async (
      submissionData: SignupMoverVariables['input'],
      { optIn }: { optIn?: boolean }
    ) => {
      setSmsCheckState(Boolean(optIn));

      return signup({
        variables: {
          input: {
            ...submissionData,
            inviteToken,
            referralCode,
          },
        },
      });
    },
    [signup, inviteToken, referralCode]
  );

  return {
    submitOnboarding,
    succesful,
  };
};

type InviteAddress =
  | InviteByToken_inviteByToken_invite_toAddress
  | InviteByToken_inviteByToken_invite_fromAddress;
const inviteAddressToFormAddress = (
  address: InviteAddress | null | undefined
) => {
  if (!address) return undefined;

  return {
    street: address.street ?? '',
    unit: address.unit ?? '',
    city: address.city ?? '',
    state: address.state ?? '',
    postalCode: address.postalCode ?? '',
  };
};

export const useInitialValues = (
  invite?: InviteByToken_inviteByToken_invite
) => {
  const { data } = useInvitedOnboardingStore();
  const { value: variant } = useFeature('consumer_skip_rent_own');

  const phone =
    invite?.phone?.length === 10 ? `1${invite?.phone}` : invite?.phone;

  const [initialValues] = useState<Partial<FormData>>({
    firstName: invite?.firstName ?? undefined,
    lastName: invite?.lastName ?? undefined,
    email: invite?.email ?? undefined,
    toAddress: inviteAddressToFormAddress(invite?.toAddress),
    fromAddress: inviteAddressToFormAddress(invite?.fromAddress),
    fromOutsideCountryAddress: {
      street: invite?.fromAddress?.street ?? '',
      unit: invite?.fromAddress?.unit ?? '',
      city: invite?.fromAddress?.city ?? '',
      country: '',
    },
    phone: phone?.trimEnd().trimStart() ?? undefined,   //remove potential malformed phone
    moveDate: invite?.estimatedMoveDate?.replace(/-/g, '/'),
    moverPass: data.moverPass ?? true,
    // consumer_skip_rent_own if skipping set to rent
    ownership: variant === 'skip' ? AddressOwnership.RENT : undefined,
    ...data,
    optIn: true,
    movingFromOutsideCountry: 'No',
  });

  return initialValues;
};
