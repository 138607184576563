import { useBridge } from '@updater/native-web';
import { useMemo } from 'react';

export function useNativeAppCommand() {
  const bridge = useBridge();

  return useMemo(
    () => ({
      active: bridge.active,
      logout: () =>
        bridge.active &&
        bridge.postMessage({
          type: 'authentication:status',
          authentication: { authenticated: false },
          source: 'consumer-app:command:logout',
        }),
    }),
    [bridge]
  );
}
