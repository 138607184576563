/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressClassification {
  COMMERCIAL = 'COMMERCIAL',
  MILITARY = 'MILITARY',
  RESIDENTIAL = 'RESIDENTIAL',
  UNKNOWN = 'UNKNOWN',
}

export enum AddressHomeSize {
  four_bed_plus = 'four_bed_plus',
  one_bed = 'one_bed',
  studio = 'studio',
  three_bed = 'three_bed',
  three_bed_plus = 'three_bed_plus',
  two_bed = 'two_bed',
}

export enum AddressOwnership {
  OWN = 'OWN',
  RENT = 'RENT',
}

export enum AddressStatus {
  APT_NEEDED = 'APT_NEEDED',
  INVALID = 'INVALID',
  VALID = 'VALID',
}

export enum ClaimedPromotionCodeType {
  barcode = 'barcode',
  confirmation_code = 'confirmation_code',
  none = 'none',
  promo_code = 'promo_code',
}

export enum ContactPreferenceType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum Direction {
  move_in = 'move_in',
  move_out = 'move_out',
  transfer = 'transfer',
  unknown = 'unknown',
}

export enum DivisionUtilitiesConfigProofFormat {
  account_number = 'account_number',
  document = 'document',
  number = 'number',
}

/**
 * Move size values supported by dolly. This does not necessarily match AddressHomeSize.
 */
export enum DollyMoveSize {
  one_bed = 'one_bed',
  studio = 'studio',
  three_bed = 'three_bed',
  two_bed = 'two_bed',
}

export enum HomeServicesCallCenter {
  bridgevine = 'bridgevine',
  kandela = 'kandela',
}

export enum HomeServicesFlowPreference {
  EXCLUSIVE = 'EXCLUSIVE',
  FEATURED = 'FEATURED',
  SHOP = 'SHOP',
  TRANSFER = 'TRANSFER',
}

export enum HomeServicesProviderFeaturedKind {
  property_exclusive = 'property_exclusive',
  property_featured = 'property_featured',
  updater_featured = 'updater_featured',
}

export enum HomeServicesReservationStatus {
  RESERVED = 'RESERVED',
}

export enum InternetKind {
  BPL = 'BPL',
  CABLE = 'CABLE',
  DSL = 'DSL',
  FIBER = 'FIBER',
  SATELLITE = 'SATELLITE',
  WIRELESS = 'WIRELESS',
}

export enum InternetProductType {
  bpl = 'bpl',
  cable = 'cable',
  dsl = 'dsl',
  fiber = 'fiber',
  satellite = 'satellite',
  wireless = 'wireless',
}

export enum InternetSpeedTier {
  MAXIMUM = 'MAXIMUM',
  MINIMUM = 'MINIMUM',
  NORMAL = 'NORMAL',
}

export enum InviteStatus {
  ANSWERED = 'ANSWERED',
  BOUNCED = 'BOUNCED',
  CANCELLED = 'CANCELLED',
  CLAIMED = 'CLAIMED',
  DUPLICATE = 'DUPLICATE',
  EMAIL_REQUESTED = 'EMAIL_REQUESTED',
  INFO_NEEDED = 'INFO_NEEDED',
  INVALID_EMAIL = 'INVALID_EMAIL',
  NOT_SENT = 'NOT_SENT',
  OPENED = 'OPENED',
  OPT_OUT = 'OPT_OUT',
  QUEUED_TO_MOVER = 'QUEUED_TO_MOVER',
  REJECTED = 'REJECTED',
  REQUEUED_TO_MOVER = 'REQUEUED_TO_MOVER',
  RESENT_TO_MOVER = 'RESENT_TO_MOVER',
  REVIEW = 'REVIEW',
  REVIEWED = 'REVIEWED',
  SENT_TO_MOVER = 'SENT_TO_MOVER',
}

export enum OfferValueTier {
  BAD = 'BAD',
  GOOD = 'GOOD',
  OK = 'OK',
}

export enum PhoneKind {
  CABLE = 'CABLE',
  FIBER = 'FIBER',
  LANDLINE = 'LANDLINE',
  SATELLITE = 'SATELLITE',
  VOIP = 'VOIP',
}

export enum PhoneProductType {
  cable = 'cable',
  fiber = 'fiber',
  landline = 'landline',
  satellite = 'satellite',
  voip = 'voip',
}

export enum PhoneVerificationMethodInput {
  CALL = 'CALL',
  SMS = 'SMS',
}

export enum PromotionClaimType {
  digital = 'digital',
  physical = 'physical',
}

export enum PromotionCodeType {
  confirmation_code = 'confirmation_code',
  dynamic_class = 'dynamic_class',
  none = 'none',
  static_barcode = 'static_barcode',
  static_promo_code = 'static_promo_code',
}

export enum PromotionPlacement {
  claim_go_redirect = 'claim_go_redirect',
  email = 'email',
  exclusive_offers = 'exclusive_offers',
  forward_mail = 'forward_mail',
  moverpass = 'moverpass',
  moving = 'moving',
  owner_only = 'owner_only',
  splash_page = 'splash_page',
}

export enum PromotionRedemptionMethodType {
  informational = 'informational',
  location = 'location',
  website = 'website',
}

export enum PromotionStatus {
  draft = 'draft',
  expired = 'expired',
  live = 'live',
  paused = 'paused',
}

export enum ServiceabilityCachingStrategy {
  aggressive = 'aggressive',
  conservative = 'conservative',
  none = 'none',
}

export enum ServiceabilityChargeType {
  activation = 'activation',
  early_cancellation = 'early_cancellation',
  installation = 'installation',
  long_term = 'long_term',
  short_term = 'short_term',
}

export enum ServiceabilityDisclaimerDisplayLocation {
  details = 'details',
  list_page = 'list_page',
  proximity = 'proximity',
}

export enum ServiceabilityInternetSpeedUnit {
  gbps = 'gbps',
  kbps = 'kbps',
  mbps = 'mbps',
}

export enum ServiceabilityProviderAvailability {
  available = 'available',
  unavailable = 'unavailable',
  unknown = 'unknown',
}

export enum ServiceabilityResultStatus {
  cache = 'cache',
  error = 'error',
  ok = 'ok',
}

export enum ServiceabilityTermUnit {
  month = 'month',
  year = 'year',
}

export enum Statuses {
  cancelled = 'cancelled',
  completed = 'completed',
  info_requested = 'info_requested',
  started = 'started',
  validating = 'validating',
}

export enum SubscriptionPreferencesChannel {
  email_marketing = 'email_marketing',
}

export enum SubscriptionPreferencesMessage {
  abandonment = 'abandonment',
  invite = 'invite',
  lifecycle = 'lifecycle',
  promotional = 'promotional',
  survey = 'survey',
}

export enum TvKind {
  CABLE = 'CABLE',
  FIBER = 'FIBER',
  IPTV = 'IPTV',
  SATELLITE = 'SATELLITE',
}

export enum TvPackage {
  FAMILY = 'FAMILY',
  LATINO = 'LATINO',
  NEWS = 'NEWS',
  PREMIUM = 'PREMIUM',
  SPORTS = 'SPORTS',
}

export enum TvProductType {
  cable = 'cable',
  fiber = 'fiber',
  iptv = 'iptv',
  satellite = 'satellite',
}

export enum UtilityMatchLevel {
  POSTALCODE = 'POSTALCODE',
  PROPERTY = 'PROPERTY',
}

export enum VerifyPinUserFieldInput {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export interface AdditionalInsuredInput {
  firstName: string;
  lastName: string;
  residentId?: string | null;
}

export interface AddressInput {
  city: string;
  homeSize?: AddressHomeSize | null;
  postalCode: string;
  state: string;
  street: string;
  unit?: string | null;
}

export interface AnswerInviteInput {
  token: string;
}

export interface AvailableOffersInput {
  moveId?: string | null;
  address?: AddressInput | null;
  providerCodes?: (string | null)[] | null;
  cachingStrategy?: ServiceabilityCachingStrategy | null;
  experiments?: (Experiment | null)[] | null;
  source?: string | null;
  optionalFields?: OptionalFields | null;
  promoId?: string | null;
}

export interface AvailableUtilityProviderAddress {
  city?: string | null;
  id?: string | null;
  postalCode?: string | null;
  state?: string | null;
  street?: string | null;
  unit?: string | null;
}

export interface AvailableUtilityProviderFilter {
  enabled?: boolean | null;
  matchLevel?: UtilityMatchLevel | null;
}

export interface AvailableUtilityProviderInput {
  address?: AvailableUtilityProviderAddress | null;
  divisionId?: string | null;
  filter?: AvailableUtilityProviderFilter | null;
}

export interface ChangePasswordInput {
  newPassword: string;
  pwChangeToken: string;
}

export interface ClaimPromotionPublicInput {
  promotionCode?: string | null;
  promotionId?: string | null;
  source?: PromotionPlacement | null;
  userUuid?: string | null;
}

export interface CreateItemInput {
  kind: string;
  metadata?: any | null;
  status?: Statuses | null;
  userUuid?: string | null;
}

export interface DollyEligibilityInput {
  addresses: AddressInput[];
  moveDateRange?: any[] | null;
  moveSize?: DollyMoveSize | null;
}

export interface DollyMoveInput {
  fromAddress: AddressInput;
  moveDate: any;
  moveId: string;
  toAddress: AddressInput;
}

export interface DownloadResidentDocumentInput {
  documentKey: string;
  residentId?: string | null;
}

export interface EmailAvailableInput {
  email: string;
  inviteToken?: string | null;
}

export interface Experiment {
  name: string;
  variationKey: string;
}

export interface HomeServiceReservationUserInput {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface HomeServicesAddressInput {
  city: string;
  postalCode: string;
  state: string;
  street: string;
  unit?: string | null;
}

export interface HomeServicesBridgevineMetadataInput {
  sessionId: string;
  transactionId: string;
}

export interface HomeServicesCallCenterInput {
  name: HomeServicesCallCenter;
  outbound?: boolean | null;
}

export interface HomeServicesMetadataInput {
  bridgevine?: HomeServicesBridgevineMetadataInput | null;
  checkoutEnabled?: boolean | null;
}

export interface HomeServicesOfferInput {
  code: string;
  disclaimer?: string | null;
  extendedHighlights?: (string | null)[] | null;
  highlights?: (string | null)[] | null;
  id: string;
  introductoryPrice?: HomeServicesOfferIntroductoryPriceInput | null;
  metadata?: any | null;
  name: string;
  price?: number | null;
  promo?: HomeServicesOfferPromoInput | null;
  promotions?: (HomeServicesOfferPromotionInput | null)[] | null;
  provider: HomeServicesProviderInput;
  proximityDisclaimer?: ProximityDisclaimerInput | null;
  services: HomeServicesOfferServicesInput;
  tags?: (string | null)[] | null;
  term: string;
  valueScore?: number | null;
  valueTier?: OfferValueTier | null;
}

export interface HomeServicesOfferIntroductoryPriceInput {
  duration?: number | null;
  price?: number | null;
  savingsPercent?: number | null;
  term?: string | null;
}

export interface HomeServicesOfferPromoInput {
  duration?: number | null;
  price?: number | null;
  savingsPercent?: number | null;
  term?: string | null;
}

export interface HomeServicesOfferPromotionInput {
  disclaimer?: string | null;
  promotion?: string | null;
}

export interface HomeServicesOfferServicesInput {
  internet?: InternetServiceInput | null;
  phone?: PhoneServiceInput | null;
  tv?: TvServiceInput | null;
}

export interface HomeServicesPreferencesInput {
  flow?: HomeServicesFlowPreference | null;
  internet: InternetPreferenceInput;
  phone: PhonePreferenceInput;
  propertyConfig?: HomeServicesPropertyConfigInput | null;
  tv: TvPreferenceInput;
  updaterPropertyConfig?: HomeServicesPropertyConfigInput | null;
}

export interface HomeServicesPropertyConfigInput {
  allowSatellite?: boolean | null;
  providers?: (HomeServicesProviderInput | null)[] | null;
}

export interface HomeServicesProviderInput {
  exclusive: boolean;
  featured?: boolean | null;
  featuredKind?: HomeServicesProviderFeaturedKind | null;
  id: string;
  logo?: string | null;
  name?: string | null;
}

export interface InternetPreferenceInput {
  serviceRequired?: boolean | null;
  speed?: InternetSpeedTier | null;
}

export interface InternetServiceInput {
  kind?: InternetKind | null;
  speed?: InternetSpeedInput | null;
}

export interface InternetSpeedInput {
  mbps: number;
  tier: InternetSpeedTier;
}

export interface MakeHomeServicesOrderInput {
  contactInformation?: any | null;
  creditCheck?: any | null;
  installation?: any | null;
  moveId: string;
  offer: HomeServicesOfferInput;
  optIns?: any | null;
  payment: any;
  price: any;
  promotionOptions?: any | null;
  reservationCode: string;
  security?: any | null;
}

export interface MakeHomeServicesReservationInput {
  callCenter?: HomeServicesCallCenterInput | null;
  checkoutEnabled?: boolean | null;
  fromAddress?: HomeServicesAddressInput | null;
  metadata?: HomeServicesMetadataInput | null;
  moveId: string;
  offer: HomeServicesOfferInput;
  offers?: (HomeServicesOfferInput | null)[] | null;
  preferences: HomeServicesPreferencesInput;
  propertyConfig?: HomeServicesPropertyConfigInput | null;
  toAddress?: HomeServicesAddressInput | null;
  user?: HomeServiceReservationUserInput | null;
}

export interface MoveAddKeyInput {
  inviteToken?: string | null;
  key: string;
  uuid: string;
  value: string;
}

export interface MoveAddressInput {
  city: string;
  homeSize?: AddressHomeSize | null;
  ownership?: AddressOwnership | null;
  postalCode: string;
  state: string;
  street: string;
  unit?: string | null;
}

export interface MoveFindKeyInput {
  inviteToken?: string | null;
  key: string;
  uuid: string;
}

export interface MoveMetadataInput {
  personalization?: MovePersonalizationInput | null;
}

export interface MovePersonalizationInput {
  movingReason?: string | null;
  personsMoving?: string[] | null;
}

export interface OptionalFields {
  uuid?: string | null;
  gclid?: string | null;
  msclkid?: string | null;
  fbclid?: string | null;
}

export interface PhonePreferenceInput {
  landline?: boolean | null;
  serviceRequired?: boolean | null;
}

export interface PhoneServiceInput {
  kind?: PhoneKind | null;
}

export interface ProximityDisclaimerInput {
  pricingText?: string | null;
  proximityDisclosure?: string | null;
}

export interface SigninInput {
  email: string;
  password: string;
  sessionLength?: number | null;
}

export interface SignupMoveAddressInput {
  analysis?: string | null;
  city: string;
  classification?: AddressClassification | null;
  components?: string | null;
  homeSize?: AddressHomeSize | null;
  metadata?: string | null;
  ownership?: AddressOwnership | null;
  postalCode: string;
  state: string;
  status?: AddressStatus | null;
  street: string;
  unit?: string | null;
}

export interface SignupMoveFromAddressInput {
  analysis?: string | null;
  city: string;
  classification?: AddressClassification | null;
  components?: string | null;
  country?: string | null;
  homeSize?: AddressHomeSize | null;
  metadata?: string | null;
  ownership?: AddressOwnership | null;
  postalCode: string;
  state: string;
  status?: AddressStatus | null;
  street: string;
  unit?: string | null;
}

export interface SignupMoveInput {
  externalId?: string | null;
  fromAddress: SignupMoveFromAddressInput;
  metadata?: MoveMetadataInput | null;
  moveDate: any;
  sessionLength?: number | null;
  toAddress: SignupMoveAddressInput;
}

export interface SignupMoverInput {
  inviteToken?: string | null;
  move: SignupMoveInput;
  mover: SignupUserInput;
  promotions?: string[] | null;
  referralCode?: string | null;
}

export interface SignupUserInput {
  email: string;
  externalId?: string | null;
  firstName: string;
  lastName: string;
  metadata?: UserMetadataInput | null;
  middleName?: string | null;
  password: string;
  phone?: string | null;
  suffixName?: string | null;
}

export interface SiteBrandingInput {
  inviteToken?: string | null;
  subdomain?: string | null;
}

export interface SubmitItemAnswerInput {
  finalSubmit?: boolean | null;
  itemId: number;
  skipReview?: boolean | null;
  submission: any;
}

export interface SubscriptionFindPreferencesInput {
  inviteToken?: string | null;
  userUuid?: string | null;
}

export interface SubscriptionPreferencesInput {
  channel: SubscriptionPreferencesChannel;
  messageType: SubscriptionPreferencesMessage;
  subscribe: boolean;
}

export interface SubscriptionUpsertPreferencesInput {
  inviteToken?: string | null;
  subscriptionPreferences: SubscriptionPreferencesInput[];
  userUuid?: string | null;
}

export interface TvChannelsInput {
  count: number;
}

export interface TvFeaturesInput {
  dvr?: boolean | null;
  onDemand?: boolean | null;
}

export interface TvNetworksInput {
  cinemax?: boolean | null;
  hbo?: boolean | null;
  showtime?: boolean | null;
  starz?: boolean | null;
}

export interface TvPreferenceInput {
  cable?: boolean | null;
  packages?: (TvPackage | null)[] | null;
  serviceRequired?: boolean | null;
}

export interface TvServiceInput {
  additionalLanguages?: (string | null)[] | null;
  channels?: TvChannelsInput | null;
  features?: TvFeaturesInput | null;
  kind?: TvKind | null;
  longDescription?: string | null;
  networks?: TvNetworksInput | null;
  shortDescription?: string | null;
}

export interface UpdateItemInput {
  code?: string | null;
  id?: number | null;
  metadata?: any | null;
  status?: Statuses | null;
}

export interface UpdateMoveInput {
  date?: any | null;
  externalId?: string | null;
  fromAddress?: MoveAddressInput | null;
  id?: string | null;
  toAddress?: MoveAddressInput | null;
}

/**
 * Update user identified by id, externalId, otherwise the current user.
 */
export interface UpdateUserInput {
  email?: string | null;
  externalId?: string | null;
  firstName?: string | null;
  id?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  onboardingCompleted?: boolean | null;
  phone?: string | null;
  phoneVerificationMethod?: PhoneVerificationMethodInput | null;
  suffixName?: string | null;
}

export interface UserMetadataInput {
  preferences?: UserPreferencesInput | null;
}

export interface UserPreferencesInput {
  allowCall?: boolean | null;
}

export interface VerifyPinInput {
  pin: string;
  userField: VerifyPinUserFieldInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
