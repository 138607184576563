export enum ItemStatuses {
  ACTION_REQUIRED = 'action_required', // FE only, not an actual API status.
  ALREADY_DONE = 'already_done', // FE only, not an actual API status.
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  CLAIMED = 'claimed',
  NOT_NEEDED = 'not_needed', // FE only, not an actual API status.
  NOT_STARTED = 'not_started',
  PENDING = 'pending',
  RESERVED = 'reserved',
  STARTED = 'started',
  UNVERIFIED = 'unverified',
  VALIDATING = 'validating',
  CALLED = 'called',
  NEEDS_CONFIRMATION = 'needs_confirmation',
  INFORMATION_REQUESTED = 'information_requested',
  INFO_REQUESTED = 'info_requested',
  VALIDATED_AND_COMPLETED = 'validated_and_completed', // FE only
  SCRUBBING = 'scrubbing',
}
