import {
  Experiment,
  FeatureResult,
  GrowthBook,
  GrowthBookProvider,
  Result,
} from '@growthbook/growthbook-react';
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useAuth } from 'context/auth/Auth';
import { useDeviceId } from 'utils/use-device-id';
import {
  useInvite,
  useInviteToken,
} from 'flows/core/components/onboarding/common/hooks';

import { useTracking } from 'react-tracking';
import { TrackEventInput } from '@updater/ui-tracker';
import { datadogRum } from '@datadog/browser-rum';
import { useServerProps } from 'utils/ServerProps';
import { useUserData } from 'flows/core/hooks/use-profile';
import { useSiteBranding } from 'flows/resident-onboarding/hooks/use-site-branding';
import type { getUserInfo_getUser_user } from 'types/generated/getUserInfo';
import type { InviteByToken_inviteByToken_invite } from 'types/generated/InviteByToken';
import type {
  siteBranding_siteBranding,
  siteBranding_siteBranding_entityBrandings,
} from 'types/generated/siteBranding';
import { CONSUMER_APP_SPECTRUM_EXCLUSIVE_PROVIDER } from 'constants/experiments';

export const GrowthbookFeaturesToInitializeOnSessionStart = [
  CONSUMER_APP_SPECTRUM_EXCLUSIVE_PROVIDER.NAME,
];

const useUserId = () => {
  const { currentUserUUID, isInitialized } = useAuth();
  const {
    loading: inviteLoading,
    called: inviteCalled,
    data: inviteData,
  } = useInvite();

  return useMemo(() => {
    // If auth isn't initialized OR we're landing on a sign up page with an inivte token we've gotta wait till we get the user's uuid
    if (!isInitialized || (inviteCalled && inviteLoading)) {
      return undefined;
    }

    // If the inviteByToken query was made, and it's no longer loading we use the invite's uuid
    // This uuid will become the user's uuid after they sign up.
    // Repeat users invites will have the same uuid. This is because the uuid keeps following the user and their
    // invites around in order to make sure we track them succesfully.
    if (
      inviteCalled &&
      !inviteLoading &&
      inviteData?.inviteByToken?.invite?.uuid
    ) {
      return inviteData?.inviteByToken?.invite?.uuid;
    }

    return currentUserUUID;
  }, [currentUserUUID, isInitialized, inviteLoading, inviteCalled, inviteData]);
};

const useUserObject = () => {
  const id = useUserId();
  const deviceId = useDeviceId();
  const userAttributes: ConsumerUserAttributes =
    useDefaultGrowthbookUserAttributes();
  return useMemo(() => {
    return {
      id,
      deviceId,
      ...userAttributes,
    };
  }, [id, deviceId, JSON.stringify(userAttributes)]);
};

type TrackingCallback = (
  experiment: Experiment<any>,
  result: Result<any>
) => void;
type FeatureUsageCallback = (featureKey: string, result: FeatureResult) => void;

const trackingSubscriptions = new Set<TrackingCallback>();
const featureUsageSubscriptions = new Set<FeatureUsageCallback>();

export const useGrowthbookTracking = (cb: TrackingCallback) => {
  useEffect(() => {
    trackingSubscriptions.add(cb);
    return () => {
      trackingSubscriptions.delete(cb);
    };
  }, [cb]);
};

export const useGrowthbookFeatureUsage = (cb: FeatureUsageCallback) => {
  useEffect(() => {
    featureUsageSubscriptions.add(cb);
    return () => {
      featureUsageSubscriptions.delete(cb);
    };
  }, [cb]);
};

const datadogFeatureNotifier: FeatureUsageCallback = (featureKey, result) => {
  datadogRum.addFeatureFlagEvaluation(featureKey, result.value);
};

const useCreateGrowthbookClient = () => {
  const { growthbookAPIHost, growthbookSDKKey } = useServerProps();
  return useMemo(() => {
    return new GrowthBook({
      apiHost: growthbookAPIHost,
      clientKey: growthbookSDKKey,
      enableDevMode: true,
      trackingCallback: (experiment, result) => {
        trackingSubscriptions.forEach((cb) => {
          try {
            cb(experiment, result);
          } catch (e) {
            console.error(e);
          }
        });
      },
      onFeatureUsage: (featureKey, result) => {
        featureUsageSubscriptions.forEach((cb) => {
          try {
            cb(featureKey, result);
          } catch (e) {
            console.error(e);
          }
        });
      },
    });
  }, [growthbookAPIHost, growthbookSDKKey]);
};

export const GrowthbookAppWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const gbClient = useCreateGrowthbookClient();

  useEffect(() => {
    // Load features from the GrowthBook API
    gbClient.loadFeatures({
      timeout: 4000,
    });
  }, []);

  const userAttributes = useUserObject();

  // TODO: we will replace optimizely with growthbook in this ticket - https://updater.atlassian.net/browse/TVI-804
  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'optimizely',
  });

  const trackingCallback = useCallback<TrackingCallback>(
    (experiment, result) => {
      console.log('tracking callback fired for experiment', experiment);
      trackEvent({
        object: 'experiment',
        verb: 'tracked',
        details: {
          experiments: {
            [experiment.key]: result.value,
          },
          bucketingId: result.hashValue,
        },
      });
      trackEvent({
        object: 'user',
        verb: 'updated',
        details: {
          experiments: {
            [experiment.key]: result.value,
          },
          bucketingId: result.hashValue,
        },
      });
      // TODO: we will replace optimizely with growthbook in this ticket - https://updater.atlassian.net/browse/TVI-804
      trackEvent({
        object: 'optimizelyTrack',
        verb: 'tracked',
        details: {
          eventKey: experiment.key,
          result,
          bucketingId: result.hashValue,
        },
      });
    },
    [trackEvent]
  );

  useGrowthbookTracking(trackingCallback);

  useGrowthbookFeatureUsage(datadogFeatureNotifier);

  useEffect(() => {
    // Set user attributes for targeting (from cookie, auth system, etc.)
    gbClient.setAttributes(userAttributes);
    GrowthbookFeaturesToInitializeOnSessionStart.forEach((featureKey) =>
      gbClient.evalFeature(featureKey)
    );
  }, [userAttributes]);

  return (
    <GrowthBookProvider growthbook={gbClient}>{children}</GrowthBookProvider>
  );
};

export type ConsumerUserAttributes = {
  user_email?: string;
  user_uuid?: string;
  user_move_ownership?: string;
  user_move_to_home_size?: string;
  user_move_from_home_size?: string;
  user_move_to_state?: string;
  user_move_created_at?: number;
  user_resident_onboarding_enabled?: boolean;
  user_move_direction?: string;
  entity_ids?: string;
};

const isPropertyManagementUser = (
  brandings: (siteBranding_siteBranding_entityBrandings | null)[]
) =>
  brandings.some(
    (branding) => branding && branding.kind === 'property_management'
  );

export const constructDefaultGrowthbookUserAttributes = ({
  user,
  invite,
  branding,
}: {
  user: getUserInfo_getUser_user | undefined;
  invite: InviteByToken_inviteByToken_invite | undefined;
  branding: siteBranding_siteBranding | undefined;
}) => {
  const createdAtInMilliseconds = new Date(
    user?.currentMove?.createdAt
  ).valueOf();
  const entity_ids = branding?.entityBrandings
    ? branding.entityBrandings
        ?.map?.((siteBranding) =>
          siteBranding ? `[${siteBranding?.entityId}]` : ''
        )
        ?.join(',')
    : '';

  const isPMMove =
    branding?.entityBrandings &&
    isPropertyManagementUser(branding.entityBrandings);

  return {
    user_email: user?.email ?? '',
    user_uuid: user?.uuid ?? '',
    user_move_ownership: user?.currentMove?.toAddress?.ownership ?? '',
    user_move_to_home_size: user?.currentMove?.toAddress?.homeSize ?? '',
    user_move_from_home_size: user?.currentMove?.fromAddress?.homeSize ?? '',
    user_move_to_state: user?.currentMove?.toAddress?.state ?? '',
    user_move_created_at: createdAtInMilliseconds || Date.now(),
    user_resident_onboarding_enabled:
      !!invite?.residentOnboardingEnabled ||
      !!user?.currentMove?.residentOnboardingEnabled,
    user_move_direction:
      invite?.direction || user?.currentMove?.direction || '',
    user_move_is_pm_move: !!isPMMove,
    entity_ids,
  };
};

export const useDefaultGrowthbookUserAttributes = () => {
  const user = useUserData();
  const invite = useInvite();
  const { data: branding } = useSiteBranding({
    inviteToken: useInviteToken(),
  });

  return useMemo(() => {
    if (user.loading || user.error || invite.loading || invite.error) {
      return {};
    }

    const inviteData = invite.data?.inviteByToken ?? undefined;

    return {
      ...constructDefaultGrowthbookUserAttributes({
        user: user.user,
        invite: inviteData?.invite ?? undefined,
        branding: branding?.siteBranding ?? undefined,
      }),
    };
  }, [user, invite, branding]);
};
