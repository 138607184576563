/* eslint-disable react/destructuring-assignment */
import React, { ErrorInfo, PropsWithChildren } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { DefaulErrorPage } from './DefaultErrorPage';

type BaseErrorBoundaryProps = {
  uuid: string;
  trackEvent: (data: Partial<{}>) => void;
  ErrorPage?: React.FC<Partial<{ logout: () => void; error: Error }>>;
  logout: () => void;
  domain: string;
};
type BaseErrorBoundaryState =
  | {
      hasError: false;
      error: undefined;
    }
  | {
      hasError: true;
      error: Error;
    };

export class BaseErrorBoundary extends React.Component<
  PropsWithChildren<BaseErrorBoundaryProps>,
  BaseErrorBoundaryState
> {
  constructor(props: BaseErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn('[ERROR BOUNDRY] ', error, errorInfo);
    this.props.trackEvent({
      object: 'error',
      verb: 'tracked',
      details: {
        errorInfo,
        error,
        message: error?.message,
        errorBoundaryDomain: this.props.domain,
      },
    });
    this.props.trackEvent({
      object: 'error_page',
      verb: 'viewed',
      details: {
        errorInfo,
        error,
        message: error?.message,
        errorBoundaryDomain: this.props.domain,
      },
    });
    datadogRum.addError(error, {
      errorInfo,
      uuid: this.props.uuid,
      errorBoundaryDomain: this.props.domain,
    });
  }

  render() {
    if (this.state.hasError) {
      const ErrorPage = this.props.ErrorPage || DefaulErrorPage;
      return <ErrorPage error={this.state.error} logout={this.props.logout} />;
    }

    return <>{this.props.children}</>;
  }
}
