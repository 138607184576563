import { useBridge } from '@updater/native-web';
import { useRouterEvent, RouterEvent } from './use-router-event';

const events: { [key in RouterEvent]?: string } = {
  routeChangeStart: 'started',
  routeChangeError: 'failed',
  routeChangeComplete: 'completed',
};

export function useRouterEventRelay() {
  const bridge = useBridge();

  useRouterEvent(
    ({ type, path }) =>
      bridge.status === 'webview:present' &&
      bridge.postMessage({
        type: `app:route-change:${events[type] ?? 'unknown'}`,
        path,
      }),
    Object.keys(events) as RouterEvent[]
  );
}
