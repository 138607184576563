import { datadogRum } from '@datadog/browser-rum';
import { Config } from '../constants';

type ApolloErrorContext = Error & {
  clientErrors: unknown[];
  extraInfo: unknown;
  graphQLErrors: unknown[];
  message: string;
  networkError: TypeError & { cause?: Record<string, string> };
};

const errorIsApolloNetworkErrorWithCause = (
  object: unknown
): object is ApolloErrorContext => {
  return (
    !!object &&
    typeof object === 'object' &&
    'networkError' in object &&
    typeof (object as ApolloErrorContext).networkError === 'object' &&
    'cause' in (object as ApolloErrorContext).networkError
  );
};

export const init = (env: string) => {
  datadogRum.init({
    applicationId: Config.APPLICATION_ID,
    clientToken: Config.CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env,
    sessionSampleRate: 100,
    service: 'consumer-app',
    site: 'datadoghq.com',
    trackUserInteractions: true,
    trackResources: true,
    enableExperimentalFeatures: ["feature_flags"],
    // eslint-disable-next-line no-underscore-dangle
    version: global.__VERSION_STRING__,
    allowedTracingUrls: ['https://api.updater.com', 'https://api.staging.updater.com'],
    beforeSend: (event, context) => {
      if (event.type === 'resource') {
        if (
          event.resource.method === 'DELETE' &&
          event.resource.url.endsWith('/v2/sessions')
        ) {
          console.log('discarding logout API call');
          return false;
        }
        let parsedBody;
        try {
          parsedBody = JSON.parse((context as any).requestInit?.body);
        } catch (e) {
          parsedBody = {};
        }
        if (
          event.resource.url.endsWith('/graphql') &&
          event.resource.method === 'POST'
        ) {
          // eslint-disable-next-line no-param-reassign
          event.context = {
            ...event.context,
            graphQLOperationName: parsedBody.operationName,
            graphQLOperationType: parsedBody.query?.match(/^([\w]+)\s/)[1],
          };
        }
      }

      if (
        event.type === 'error' &&
        'error' in context &&
        errorIsApolloNetworkErrorWithCause(context.error)
      ) {
        event.context = {
          ...event.context,
          ...(context.error.networkError?.cause || {}),
        };
      }

      if (typeof window !== 'undefined' && window.FS) {
        const fsSessionUrl = window?.FS?.getCurrentSessionURL?.();
        event.context!.fullStoryUrl = fsSessionUrl; // eslint-disable-line
      }

      return true;
    },
  });
};
