import { useNativeWebProps } from '@updater/native-web';
import { useMemo } from 'react';
import { useRouterEvent } from './use-router-event';

export function useRouteCapture() {
  const props = useNativeWebProps();
  const patterns = useMemo<RegExp[]>(
    () =>
      // @ts-expect-error: not yet implemented in native-web
      (props?.routeCapture?.patterns ?? []).map(
        (pattern: string) => new RegExp(pattern, 'gi')
      ),
    [props]
  );

  useRouterEvent(
    (target, router) =>
      patterns.some((pattern) => pattern.test(target.path)) &&
      // when it matches we prevent a route change
      router.events.emit('routeChangeError'),
    ['routeChangeStart']
  );
}
