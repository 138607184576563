import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  brandedModalQuery,
  brandedModalQueryVariables,
} from '../../types/generated/brandedModalQuery';

const QUERY = gql`
  query brandedModalQuery($input: SiteBrandingInput!) {
    siteBranding(input: $input) {
      subdomain

      entityBrandings {
        name
        displayName
        teamName
        kind
        email
        showBranding
        logo {
          id
          baseUrl
          url
        }
        entityId
        socialBranding {
          facebook
          twitter
          linkedin
        }
      }
    }
  }
`;

export const useBrandedProModalQuery = (
  options: QueryHookOptions<brandedModalQuery, brandedModalQueryVariables>
) => useQuery<brandedModalQuery, brandedModalQueryVariables>(QUERY, options);
