import React, { memo, ReactElement, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Box, Flex, Text, useViewportSizes } from '@updater/ui-uds';

interface NavButtonProps {
  icon: ReactElement;
  text: string;
  isActive: boolean;
  dot?: boolean;
  onClick: () => void;
}

type TextDecoratorTypes = 'none' | 'underline';
interface NavItemProps {
  isActive: boolean;
}
interface NavItemTextProps {
  textDecoration: TextDecoratorTypes;
}

const NavElement = styled(Flex)<NavItemProps>(
  ({ theme }) => `
  &:hover {
    cursor: pointer;
    ${theme.mediaQueries.l} {
      filter: brightness(0.8);
    }
  }
`
);

const IconWrapper = styled(Flex)`
  border-radius: 50%;
`;

const NavItemText = styled(Text)<NavItemTextProps>(
  ({ theme, textDecoration }) => `
  text-decoration: none;
  ${theme.mediaQueries.l} {
    text-decoration: ${textDecoration};
  }
  line-height: normal !important;
`
);

export const NavBtn: React.FC<NavButtonProps> = ({
  icon,
  text,
  isActive,
  dot,
  onClick,
}) => {
  const { isMobile } = useViewportSizes();

  const [textDecoration, setTextDecoration] =
    useState<TextDecoratorTypes>('none');
  const theme = useTheme();

  const iconProps = {
    color: isActive ? theme.colors.navBarActiveItem : theme.colors.primary,
  };

  // This is done to pass additional props to the Icon
  const clonedIcon = React.cloneElement(icon, iconProps);

  return (
    <NavElement
      isActive={isActive}
      onClick={onClick}
      data-testid={text}
      as="button"
      height={['66px', '66px', '66px', 'unset']}
      flexDirection={['column', 'column', null, 'row']}
      alignItems="center"
      border="none"
      margin="none"
      padding="none"
      marginBottom={['none', null, null, 'l', null, 'xl']}
      marginTop={['xxs', 'xxs', null]}
      justifyContent={['space-around', 'space-around', null, 'initial']}
      backgroundColor="transparent"
      width={['unset', 'unset', null, '100%']}
      onMouseEnter={() => setTextDecoration('underline')}
      onMouseLeave={() => setTextDecoration('none')}
    >
      <IconWrapper
        justifyContent="center"
        alignItems="center"
        width={['32px', '32px', '56px', '32px', '32px']}
        height={['32px', '32px', '56px', '32px', '32px']}
        margin="none"
        marginRight={['none', 'none', null, 's']}
        position="relative"
      >
        <Flex
          width={['18px', '18px', '28px', '18px', '18px']}
          height={['18px', '18px', '28px', '18px', '18px']}
          justifyContent="center"
          alignItems="center"
        >
          {clonedIcon}
        </Flex>
        {isMobile && dot && (
          <Box
            data-testid="notification-dot"
            height="8px"
            width="8px"
            backgroundColor="brand"
            borderRadius="50%"
            position="absolute"
            top="1px"
            right="-5px"
          />
        )}
      </IconWrapper>
      <Flex alignItems="center">
        <NavItemText
          variant={[
            isActive ? 'xsBold' : 'xs',
            isActive ? 'xsBold' : 'xs',
            isActive ? 'xsBold' : 'xs',
            isActive ? 'lBold' : 'l',
            isActive ? 'lBold' : 'l',
          ]}
          as="p"
          color="primary"
          textDecoration={textDecoration}
        >
          {text}
        </NavItemText>
        {!isMobile && dot && (
          <Box
            data-testid="notification-dot"
            height="6px"
            width="6px"
            backgroundColor="brand"
            borderRadius="50%"
            marginLeft="xxs"
          />
        )}
      </Flex>
    </NavElement>
  );
};

export const NavButton = memo(NavBtn);
