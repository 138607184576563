import { gql } from '@apollo/client';

export const UpdateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        updatedAt
        firstName
        lastName
        id
      }
    }
  }
`;

export const UpdateMoveDateMutation = gql`
  mutation UpdateMoveDate($input: UpdateMoveInput!) {
    updateMove(input: $input) {
      move {
        date
        id
      }
    }
  }
`;

export const StartPasswordChangeMutation = gql`
  mutation startPasswordChange($existingPW: String!) {
    startPasswordChange(existingPW: $existingPW) {
      pwChangeToken
    }
  }
`;

export const ChangePasswordMutation = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      status
    }
  }
`;

export const VerifyPinMutation = gql`
  mutation verifyPin($input: VerifyPinInput!) {
    verifyPin(input: $input) {
      success
    }
  }
`;

export const GetSubdomain = gql`
  query getSubdomain {
    siteBranding(input: {}) {
      subdomain
    }
  }
`;

export const GetUserInfoQuery = gql`
  query getUserInfo($id: ID) {
    getUser(id: $id) {
      user {
        email
        firstName
        lastName
        middleName
        id
        phone
        suffixName
        uuid
        createdAt
        currentMove {
          residentOnboardingEnabled
          createdAt
          date
          id
          canEditAddresses
          direction
          hasRoommates
          toAddress {
            city
            homeSize
            id
            postalCode
            state
            street
            unit
            ownership
          }
          fromAddress {
            city
            homeSize
            id
            postalCode
            state
            street
            international
            unit
          }
        }
        metadata {
          preferences {
            allowCall
          }
        }
      }
    }
  }
`;

export const GetUsersUtilityProvidersQuery = gql`
  query availableUtilityProviders($input: AvailableUtilityProviderInput) {
    availableUtilityProviders(input: $input) {
      utilityProviders {
        logoImage {
          id
          url
          baseUrl
        }
        name
        enabled
        localPhone
        businessPhone
        kind
        id
        website
      }
    }
  }
`;

// NOTE: this query is not in the core api.  it's proxied through the core api
// to the services monorepo "utility" stack.  this query constructed from this file:
// https://github.com/Updater/services/blob/master/stacks/task/app/src/core_api/core_api_types.ts#L55-L85
export const GetUtilitiesQuery = gql`
  query getUtilities($input: AvailableUtilityProviderInput!) {
    availableUtilityProviders(input: $input) {
      utilityProviders {
        id
        enabled
        kind
        name
        businessPhone
        localPhone
        website
        logoImage {
          id
        }
        __typename
      }
      __typename
    }
  }
`;

export const GetDivision = gql`
  query getDivision($divisionId: ID!) {
    division(id: $divisionId) {
      utilitiesConfig {
        electric
        electricProofFormat
        gas
        gasProofFormat
        water
        waterProofFormat
        __typename
      }
      __typename
    }
  }
`;

export const SubmitItemAnswerMutation = gql`
  mutation SubmitItemAnswer($input: SubmitItemAnswerInput!) {
    submitItemAnswer(input: $input) {
      item {
        id
        kind
        status
        metadata
      }
    }
  }
`;

export const GetDollyEligibilityQuery = gql`
  query dollyEligibility($input: DollyEligibilityInput!) {
    dollyEligibility(input: $input) {
      eligible
      denialReason {
        serviceReason
        complianceReason
      }
      addressEligibility {
        eligible
        denialReason {
          serviceReason
          complianceReason
        }
      }
    }
  }
`;

export const GetSubscriptionPreferencesQuery = gql`
  query getSubscriptionPreferences($input: SubscriptionFindPreferencesInput) {
    subscriptionPreferences(input: $input) {
      subscriptionPreferences {
        channel
        messageType
        subscribe
      }
    }
  }
`;

export const UpdateSubscriptionPreferencesMutation = gql`
  mutation UpsertSubscriptionPreferences(
    $input: SubscriptionUpsertPreferencesInput!
  ) {
    upsertSubscriptionPreferences(input: $input) {
      subscriptionPreferences {
        subscribe
        messageType
        channel
      }
    }
  }
`;

export const GetDollyEligibility = gql`
  query dollyMove($input: DollyMoveInput!) {
    dollyMove(input: $input) {
      eligible
    }
  }
`;

export const GetPreferredProviders = gql`
  query preferredProviders {
    preferredProviders {
      preferredProviders {
        contactDetail
        description
        detail
        email
        entityId
        entityIds
        category
        ownerKind
        id
        link
        logo {
          url
          id
        }
        name
        nickname
        phone
      }
      dataView {
        pagination {
          page
          perPage
          recordCount
        }
      }
    }
  }
`;

export const DownloadPropertyDocumentMutation = gql`
  mutation downloadPropertyDocument(
    $divisionId: ID!
    $documentId: String!
    $itemCode: String!
  ) {
    downloadPropertyDocument(
      divisionId: $divisionId
      documentId: $documentId
      itemCode: $itemCode
    ) {
      documentUrl
      previewUrl
    }
  }
`;
