import { TrackEventInput } from '@updater/ui-tracker';
import { useTracking } from 'react-tracking';
import { useEffect } from 'react';

type TrackEvent = ReturnType<typeof useTracking>['trackEvent'];
export function useTrackOnMount<T = {}>(
  trackEvent: TrackEvent,
  event: Partial<TrackEventInput<T>> = {}
) {
  useEffect(() => {
    trackEvent(event);
  }, []);
}

export function useTrackViewOnMount<T = {}>(
  trackEvent: TrackEvent,
  event: Partial<TrackEventInput<T>> = {}
) {
  useTrackOnMount<T>(trackEvent, { ...event, verb: 'viewed' });
}
