import { useMemo, useRef } from 'react';
import { useSafeLayoutEffect } from '@updater/ui-uds';

export function useReferencedCallback<T extends (...args: any) => any>(fn: T) {
  const ref = useRef(fn);

  // follows implementation of the useEvent React hook primitive
  useSafeLayoutEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useMemo(() => {
    return (...args: Parameters<T>): ReturnType<T> => ref.current(...args);
  }, []);
}
