import create from 'zustand';
import { persist } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import { useNativeWebProps } from '@updater/native-web';

const useDeviceIdStore = create(
  persist(
    () => ({
      id: `device-${uuidv4()}`,
    }),
    {
      name: 'DEVICE_IDS',
      getStorage: () => localStorage,
    }
  )
);

export const useDeviceId = () => {
  const props = useNativeWebProps();
  const store = useDeviceIdStore();

  return (
    // @ts-expect-error: update native-web for typing
    (props.deviceId as string | undefined) || store.id
  );
};
