import { useTheme } from '@emotion/react';
import { Flex, Grid } from '@updater/ui-uds';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

import { DESKTOP_NAVBAR_WIDTH } from 'constants/components';
import { NavButton } from './NavButton';

export type Tab = 'today' | 'essentials' | 'moving' | 'deals';

export type Path = '/' | '/today' | '/moving' | '/_error' | '/deals';

export interface NavItem {
  text: string;
  path: Path;
  key: Tab;
  icon: ReactElement;
  dot?: boolean;
  onClick: () => void;
}

interface NavBarProps {
  items: NavItem[];
}

export const NavBar: React.FC<NavBarProps> = ({ items }) => {
  const router = useRouter();
  const theme = useTheme();
  const height = [
    'calc(86px + env(safe-area-inset-bottom, 0) * .6 )',
    'calc(86px + env(safe-area-inset-bottom, 0) * .6 )',
    'calc(86px + env(safe-area-inset-bottom, 0) * .6 )',
    'auto',
  ];

  return items.length === 0 ? null : (
    <Flex
      as="nav"
      display="flex"
      position={['fixed', 'fixed', null, 'initial']}
      height={height}
      maxHeight={height}
      zIndex={1}
      borderRadius="none"
      overflow="hidden"
      minWidth={[null, null, null, DESKTOP_NAVBAR_WIDTH]}
      width={['100%', `100%`, null, DESKTOP_NAVBAR_WIDTH]}
      paddingTop={['xs', 'xs', null, 'm']}
      paddingLeft={['none', 'none', 'xxxxl', 'none']}
      paddingRight={['none', 'none', 'xxxxl', 'none']}
      top="unset"
      bottom="0"
      left="0"
      right="0"
      backgroundColor="navBarBackground"
      opacity="1"
      borderTop={[
        `1px solid ${theme.colors.disabled}`,
        `1px solid ${theme.colors.disabled}`,
        `1px solid ${theme.colors.disabled}`,
        'none',
      ]}
    >
      <Grid
        position={[null, null, null, 'fixed']}
        justifyItems={['center', 'center', 'center', 'left']}
        width={['100%', '100%', null, DESKTOP_NAVBAR_WIDTH]}
        marginX="auto"
        gridTemplateColumns={[
          'repeat(3, 1fr)',
          `repeat(${items.length}, 1fr)`,
          `repeat(${items.length}, 1fr)`,
          '1fr',
        ]}
        gridTemplateRows={[null, null, null, 'repeat(3, 1fr)']}
      >
        {items.map((el) => (
          <NavButton
            {...el}
            key={el.key}
            isActive={router.pathname === el.path}
          />
        ))}
      </Grid>
    </Flex>
  );
};
