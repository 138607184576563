import { useMemo } from 'react';
import { useAuth } from 'context/auth';
import { useRouter } from 'next/router';
import { useGetUserInfoQuery } from 'flows/core/hooks/use-get-user-info-query';

// TODO: add type and description
export const useHeader = () => {
  const { isLoggedIn, logout } = useAuth();
  const { data } = useGetUserInfoQuery();

  const userFirstName = data?.getUser?.user.firstName ?? undefined;
  const userToAddressUnit =
    data?.getUser?.user.currentMove?.toAddress?.unit ?? undefined;

  const router = useRouter();

  const handleAuthClick = async () => {
    if (isLoggedIn) {
      await logout();
    }
    if (!isLoggedIn) {
      router.push('/');
    }
  };

  return { isLoggedIn, handleAuthClick, userFirstName, userToAddressUnit };
};
