import { FC, PropsWithChildren, useEffect, useState } from 'react';

export const BlockServerRender: FC<PropsWithChildren> = ({ children }) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') setHidden(false);
  }, []);

  return hidden ? null : <>{children}</>;
};
