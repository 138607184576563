import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { init as initDataDog } from 'utils/data-dog-rum';
import { datadogRum } from '@datadog/browser-rum';
import { SpinnerFullPage } from '@updater/ui-uds';

export type ServerVariables = {
  apiBaseUrl: string;
  env: string;
  googlePlacesAPIKey: string;
  googleAnalytics: {
    measurementId: string;
    streamId: string;
  };
  growthbookAPIHost: string;
  growthbookSDKKey: string;
  eventStream: {
    url: string;
    batchUrl: string;
    token: string;
  };
  dollyBaseUrl: string;
  stripeClientKey: string;
  sureStripeClientKey: string;
  sureApi: {
    url: string;
    token: string;
  };
  leasingDeskUrl: string;
  webview: {
    useragent: string;
    simulated: boolean;
  };
  tviTokenServiceUrl: string;
  tokenex: {
    scriptSrc: string;
    scriptIntegrity: string;
    iFrameTimeoutInSeconds: number;
    tvi: {
      vaultId: string;
      apiKey: string;
    };
    insurance: {
      vaultId: string;
      apiKey: string;
    };
    apiUrl: string;
  };
};

const DEFAULT_SERVER_PROPS: ServerVariables & { ready: boolean } = {
  apiBaseUrl: '',
  env: '',
  googlePlacesAPIKey: '',
  googleAnalytics: {
    measurementId: '',
    streamId: '',
  },
  growthbookAPIHost: 'https://api.growthbook.prod.updatron.tools',
  growthbookSDKKey: '',
  eventStream: {
    url: '',
    batchUrl: '',
    token: '',
  },
  dollyBaseUrl: '',
  stripeClientKey: '',
  sureStripeClientKey: '',
  sureApi: {
    url: '',
    token: '',
  },
  leasingDeskUrl: '',
  ready: false,
  webview: {
    useragent: '',
    simulated: false,
  },
  tviTokenServiceUrl: '',
  tokenex: {
    scriptSrc: '',
    scriptIntegrity: '',
    iFrameTimeoutInSeconds: 300,
    tvi: {
      vaultId: '',
      apiKey: '',
    },
    insurance: {
      vaultId: '',
      apiKey: '',
    },
    apiUrl: '',
  },
};

export const ServerPropsContext = createContext<
  ServerVariables & { ready: boolean }
>(DEFAULT_SERVER_PROPS);
export const useServerProps = () => useContext(ServerPropsContext);

export const ServerProps: React.FC<
  PropsWithChildren<{
    initialServerVariables?: ServerVariables;
  }>
> = ({ initialServerVariables, children }) => {
  const [serverVariables, setServerVariables] = useState(
    initialServerVariables
  );
  useEffect(() => {
    const { tokenex, apiBaseUrl } = initialServerVariables ?? {};
    if (
      tokenex?.scriptSrc &&
      tokenex?.scriptIntegrity &&
      tokenex?.iFrameTimeoutInSeconds &&
      tokenex?.tvi?.vaultId &&
      tokenex?.tvi?.apiKey &&
      tokenex?.insurance?.vaultId &&
      tokenex?.insurance?.apiKey &&
      apiBaseUrl
    ) {
      setServerVariables(initialServerVariables);
    }
  }, [initialServerVariables]);
  useEffect(() => {
    if (serverVariables?.env) {
      initDataDog(serverVariables.env);
    }
  }, [serverVariables?.env]);

  const finalVars = useMemo(() => {
    return {
      ready: !!serverVariables?.apiBaseUrl,
      ...serverVariables,
    };
  }, [serverVariables]);

  useEffect(() => {
    if (!finalVars.ready) {
      const timer = setTimeout(() => {
        initDataDog('unknown');
        datadogRum.addError(new Error('Server Vars failed to load in time'));
      }, 500);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [finalVars.ready]);

  if (!finalVars.ready) {
    return <SpinnerFullPage />;
  }

  return (
    <ServerPropsContext.Provider
      value={finalVars as ServerVariables & { ready: boolean }}
    >
      {children}
    </ServerPropsContext.Provider>
  );
};
