import styled from '@emotion/styled';
import { TrackEventInput } from '@updater/ui-tracker';
import { Flex, Image } from '@updater/ui-uds';
import { NavBar, NavItem, Path } from 'components/nav-bar/NavBar';
import { useItemsByStatus } from 'flows/core/hooks/use-items-by-status';
import { useMergedPromotions } from 'flows/deals/hooks/use-merged-promotions';
import { usePromotionsQuery } from 'flows/deals/hooks/use-promotions-query';
import { ConsumerAppBrandedHeader } from 'components/branded-header';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTracking } from 'react-tracking';
import { useFeature } from '@growthbook/growthbook-react';

const EssentialsIconOff = '/images/nav-icons/essentials-icon-off.svg';
const EssentialsIconOn = '/images/nav-icons/essentials-icon-on.svg';
const TodayIconOff = '/images/nav-icons/today-icon-off.svg';
const TodayIconOn = '/images/nav-icons/today-icon-on.svg';
const MovingIconOn = '/images/nav-icons/moving-icon-on.svg';
const MovingIconOff = '/images/nav-icons/moving-icon-off.svg';
const DealsIconOn = '/images/nav-icons/deals-icon-on.svg';
const DealsIconOff = '/images/nav-icons/deals-icon-off.svg';

const ErrorPath = '/_error';

export const tabRoutes: Path[] = [
  '/',
  '/today',
  '/moving',
  '/deals',
  ErrorPath,
];

const NavIcon = styled(Image)<{ render: boolean }>`
  position: absolute;
  visibility: ${({ render }) => (render ? 'visible' : 'hidden')};
`;

const EssentialsIconImage = ({ isActive }: { isActive: boolean }) => (
  <>
    <NavIcon src={EssentialsIconOn} render={isActive} alt="essentials-active" />
    <NavIcon src={EssentialsIconOff} render={!isActive} alt="essentials" />
  </>
);

const TodayIconImage = ({ isActive }: { isActive: boolean }) => (
  <>
    <NavIcon src={TodayIconOn} render={isActive} alt="today-active" />
    <NavIcon src={TodayIconOff} render={!isActive} alt="today" />
  </>
);

const MovingIconImage = ({ isActive }: { isActive: boolean }) => (
  <>
    <NavIcon src={MovingIconOn} render={isActive} alt="moving-active" />
    <NavIcon src={MovingIconOff} render={!isActive} alt="moving" />
  </>
);

const DealsIconImage = ({ isActive }: { isActive: boolean }) => (
  <>
    <NavIcon src={DealsIconOn} render={isActive} alt="deals-active" />
    <NavIcon src={DealsIconOff} render={!isActive} alt="deals" />
  </>
);

const InnerContent = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.l} {
    padding: ${({ theme }) => `0 ${theme.space.m}px`};
    max-width: ${({ theme }) => `calc(1440px + ${theme.space.xxl}px)`};
  }
`;

const NavigationLayoutContent: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const route = router.pathname;

  // TODO: change to correct status when needs more status is implemented
  const needsMoreCards = useItemsByStatus('needs-more');

  const { trackEvent } = useTracking<TrackEventInput<unknown>>({
    domain: 'navigation-layout',
    details: {
      path: {
        pathname: route,
      },
    },
  });

  const navigate = (path: Path) => {
    // if the user clickes on the current tab
    if (path === route) return;
    router.push(path as Path);
  };

  const NAV_ITEMS: NavItem[] = useMemo(() => {
    // Error path has no tab items
    if (route === ErrorPath) {
      return [];
    }

    return [
      {
        text: 'Today',
        key: 'today',
        path: '/today',
        icon: <TodayIconImage isActive={route === '/today'} />,
        onClick: () => navigate('/today'),
      },
      {
        text: 'Essentials',
        key: 'essentials',
        path: '/',
        dot: needsMoreCards.length > 0,
        icon: <EssentialsIconImage isActive={route === '/'} />,
        onClick: () => navigate('/'),
      },
      {
        text: 'Moving',
        key: 'moving',
        path: '/moving',
        icon: <MovingIconImage isActive={route === '/moving'} />,
        onClick: () => navigate('/moving'),
      },
    ];
  }, [router, `${needsMoreCards}`, route]);

  const { data: promotions, loading: promotionsLoading } = usePromotionsQuery();

  const mergedPromotions = useMergedPromotions(promotions);

  const promotionList = useMemo(() => {
    if (promotionsLoading) return [];
    return mergedPromotions;
  }, [mergedPromotions, promotionsLoading]);

  const { on: dealsFeatureEnabled } = useFeature('deals');

  if (
    promotionList &&
    promotionList.length > 0 &&
    !NAV_ITEMS.find((nav) => nav.key === 'deals') &&
    dealsFeatureEnabled
  ) {
    NAV_ITEMS.push({
      text: 'Deals',
      key: 'deals',
      path: '/deals',
      icon: <DealsIconImage isActive={route === '/deals'} />,
      onClick: () => navigate('/deals'),
    });
  }

  return (
    <Flex flexDirection="column">
      <ConsumerAppBrandedHeader />
      <InnerContent
        overflow="hidden"
        maxWidth="1440px"
        marginX="auto"
        width="100%"
      >
        <NavBar items={NAV_ITEMS} />
        {children}
      </InnerContent>
    </Flex>
  );
};

export const NavigationLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const isTabsLayout =
    tabRoutes.filter((tabRoute) => tabRoute === router?.pathname).length > 0;

  if (isTabsLayout) {
    return <NavigationLayoutContent>{children}</NavigationLayoutContent>;
  }
  return <>{children}</>;
};
