import { AuthSession, store } from '@updater/auth';
import Cookies from 'js-cookie';

const sessionKeys: (keyof AuthSession)[] = ['access-token', 'client', 'uid'];

// mount local storage / cookies
export function persistAuth() {
  if (typeof window === undefined) return;

  let session: AuthSession | undefined;
  try {
    session = JSON.parse(window.localStorage.getItem('session')!);
    if (session) {
      store.setSession({
        mode: 'authenticated',
        session: {
          'access-token': (session['access-token'] ??
            // @ts-expect-error: migration — remove after 35 days … or 2024
            session.accessToken) as string,
          client: session.client,
          uid: session.uid,
        },
      });
    }
  } catch (e) {}

  // consumer session from cookies (for mover)
  if (session == null) {
    session = sessionKeys.reduce(
      (result, key) => ({ ...result, [key]: Cookies.get(key) }),
      {} as AuthSession
    );
    if (Object.values(session).every(Boolean)) {
      store.setSession({ mode: 'authenticated', session });
    }
  }

  // sync changes to local cache
  store.subscribe(({ session }) => {
    if (session) {
      window.localStorage.setItem('session', JSON.stringify(session));
      sessionKeys.forEach((key) =>
        Cookies.set(key, session[key], { domain: 'updater.com' })
      );
    } else {
      window.localStorage.removeItem('session');
      sessionKeys.forEach((key) =>
        Cookies.remove(key, { domain: 'updater.com' })
      );
    }
  });
}
