import { Box, ButtonLink, Flex, Text, Button, TextLink } from '@updater/ui-uds';
import { Warning, Envelope } from '@phosphor-icons/react';
import { ConsumerAppBrandedHeader } from 'components/branded-header';

export const DefaulErrorPage = ({ logout }: { logout: () => void }) => {
  return (
    <>
      <ConsumerAppBrandedHeader />
      <Flex justifyContent="center" alignItems="center">
        <Box maxWidth="500px" marginTop={['xs', 'l']} padding={['s']}>
          <Flex>
            <Warning />
            <Text as="h1" variant="s" display="inline-block" marginLeft="xxs">
              Application Error
            </Text>
          </Flex>
          <Text as="p" variant="xxxl">
            Oops! That’s not supposed to happen.
            <Text as="span" variant="xl" display="block" marginTop="s">
              We’re working to fix the problem. Meanwhile, try heading back to
              home, or log out and start over fresh.
            </Text>
            <Flex width="100%">
              <Box flex="1">
                <ButtonLink
                  isFluidWidth
                  href="/"
                  marginTop="l"
                  dataCy="back-home-button"
                >
                  Back to home
                </ButtonLink>
              </Box>
              <Box flex="1" marginLeft="xl">
                <Button
                  isFluidWidth
                  onClick={logout}
                  marginTop="l"
                  dataCy="logout-error-button"
                >
                  Logout
                </Button>
              </Box>
            </Flex>
          </Text>
          <TextLink marginTop="l" href="mailto:help@updater.com">
            <Box verticalAlign="middle" display="inline-block">
              {' '}
              <Envelope />{' '}
            </Box>
            Or send an email to our help desk
          </TextLink>
        </Box>
      </Flex>
    </>
  );
};
