import { gql } from '@apollo/client';

export const InviteQuery = gql`
  query InviteByToken($token: String!) {
    inviteByToken(token: $token) {
      invite {
        id
        direction
        email
        firstName
        lastName
        estimatedMoveDate
        leaseStartDate
        residentOnboardingEnabled
        fromAddress {
          city
          postalCode
          state
          street
          unit
        }
        message
        metadata
        phone
        status
        toAddress {
          unit
          street
          state
          postalCode
          city
        }
        uuid
      }
    }
  }
`;

export const signupMover = gql`
  mutation SignupMover($input: SignupMoverInput!) {
    signupMover(input: $input) {
      session {
        uid
        client
        accessToken
      }
      mover {
        id
        uuid
        phone
        currentMove {
          residentOnboardingEnabled
        }
      }
    }
  }
`;

export const getSiteBranding = gql`
  query siteBranding($input: SiteBrandingInput!) {
    siteBranding(input: $input) {
      entityBrandings {
        logo {
          url
        }
        kind
        showBranding
        name
        kind
        entityId
      }
    }
  }
`;

export const EmailAvailabilityQuery = gql`
  query EmailAvailable($input: EmailAvailableInput!) {
    emailAvailable(input: $input) {
      available
    }
  }
`;
