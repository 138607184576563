import { useRouter, NextRouter, RouterEvent } from 'next/router';
import { useEffect } from 'react';
import { useReferencedCallback } from './use-referenced-callback';

export type { RouterEvent };

export type RouterEventHandler = (
  event: { type: RouterEvent; path: string },
  router: NextRouter
) => unknown;

export function useRouterEvent(
  handler: RouterEventHandler,
  events: RouterEvent[] = [
    'routeChangeStart',
    'routeChangeError',
    'routeChangeComplete',
  ]
) {
  const router = useRouter();
  const callback = useReferencedCallback(handler);

  useEffect(() => {
    const responders = events.map((type) => {
      const responder = (path: string) => {
        return callback({ type, path }, router);
      };

      router.events.on(type, responder);
      return () => router.events.off(type, responder);
    });

    return () => {
      responders.map((end) => end());
    };
  }, [router]);
}
