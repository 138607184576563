import create from 'zustand';

interface LoginState {
  error: string;
  success: string;
  email: string;
  resetPassword: (email: string) => void;
}

/** useLoginStore manages login flow state with zustand
 * useLoginStore is a hook that can be used to get login state from within any component.
 * components will re-render on changes to state
 * @returns {LoginState} as a zustand store
 */

export const useLoginStore = create<LoginState>((set) => ({
  error: '',
  success: '',
  email: '',
  resetPassword: (email) =>
    set({
      success: 'Your new password is all set.',
      email,
    }),
}));
