import { useBridge } from '@updater/native-web';
import { useEffect, EffectCallback, DependencyList } from 'react';

export function useEffectWhenNativeBridgeActive(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const bridge = useBridge();
  useEffect(bridge.active ? effect : () => {}, deps);
}
