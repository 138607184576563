import { useEffect, useState } from 'react';

export const hasWindow = typeof window !== 'undefined';
const hasNavigator = typeof navigator !== 'undefined';

// IPhone SE firsr generations's height
const smallDeviceHeight = 570;

export type ScreenOrientation = 'landscape' | 'portrait';

export const useWindowDimensions = () => {
  const getWindowDimensionsAndOrientation = () => {
    const width: number = hasWindow && window.innerWidth;
    const height: number = hasWindow && window.innerHeight;
    const screenHeight: number = hasWindow && window.screen.height;

    const isMobile: boolean =
      hasNavigator && navigator.userAgent.includes('Mobile');

    const isSmallDevice: boolean =
      hasWindow && screenHeight < smallDeviceHeight;

    return {
      width,
      height,
      screenHeight,
      isMobile,
      isBrowser: !isMobile,
      isSmallDevice,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensionsAndOrientation()
  );

  useEffect(() => {
    if (hasWindow) {
      const handleResizeAndOrientation = () => {
        setWindowDimensions(getWindowDimensionsAndOrientation());
      };

      window.addEventListener('resize', handleResizeAndOrientation);

      return () =>
        window.removeEventListener('resize', handleResizeAndOrientation);
    }
    return null;
  }, [hasWindow]);

  return windowDimensions;
};
