import { ApolloError } from '@apollo/client';
import { useUserData } from 'flows/core/hooks/use-profile';
import { useSWRWhenAuthed } from 'utils/use-swr-when-authed';

export const useGetDivisionId = (): {
  divisionId: string;
  loading: boolean;
  error?: ApolloError;
} => {
  const { user, loading, error } = useUserData();
  const { data } = useSWRWhenAuthed(`/v2/moves/${user?.currentMove?.id}`);
  const divisionId = data?.site_entity_id
    ? data?.site_entity_id.toString()
    : '';
  return { divisionId, loading, error };
};
