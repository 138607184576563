import { useEffect } from 'react';
import { useAuth } from 'context';

/*
 * Use within the AuthProvider to track based on auth changes
 */
export const GoogleAnalyticsTracking = () => {
  const { currentUserUUID } = useAuth();

  useEffect(() => {
    // Never send a null or undefined User ID to Google Analytics
    if (currentUserUUID) {
      window.gtag?.('set', 'user_id', currentUserUUID);

      // If the user doesn’t take any actions after this, their user id will
      // never be reported, so we track a login event to force send the
      // user id to GA
      window.gtag?.('event', 'login');
    }
  }, [currentUserUUID]);

  return null;
};
