import { useMemo, useEffect, PropsWithChildren } from 'react';
import { ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import { makeClient } from '@updater/ui-utilities';
import { useAuthMigrationSignOut, useCredentials } from 'context/auth';
import { useServerProps } from 'utils/ServerProps';
import { useSession, useSignOut } from '@updater/auth';

type OnErrorParams = Parameters<typeof onError>[0];
type NetworkError = Parameters<OnErrorParams>[0]['networkError'];

const isUnauthorizedNetworkError = (networkError?: NetworkError) => {
  // https://github.com/apollographql/apollo-link/issues/300#issuecomment-518445337
  return (
    networkError &&
    'statusCode' in networkError &&
    (networkError.statusCode === 401 || networkError.statusCode === 403)
  );
};

export const ApolloClientSetup: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { session } = useSession();
  const signOut = useAuthMigrationSignOut();

  const { apiBaseUrl } = useServerProps();
  if (!apiBaseUrl) {
    datadogRum.addError(new Error('Missing apiBaseUrl'));
  }

  const logoutLink = useMemo(() => {
    return onError(({ graphQLErrors, networkError, operation }) => {
      /**
       * Verifies that the http request is not a 401 or 403
       */
      if (isUnauthorizedNetworkError(networkError)) {
        console.warn(
          'UNAUTHORIZED NETWORK RESPONSE! Query:',
          operation.operationName
        );
        datadogRum.addError(
          new Error(
            `UNAUTHORIZED NETWORK RESPONSE FOR QUERY ${operation?.operationName}`
          ),
          {
            ...operation,
          }
        );
        // TODO: instrument this as a notification — such as a toast
        // 'Oops, something went wrong on our end! Please log back in to try again.'
        signOut();
      }
      // mutates networkError so datadog may distinguish each graph request
      if (networkError) {
        const definitionNode = operation.query.definitions?.[0];
        networkError.cause = {
          graphQLOperationName: operation.operationName,
          graphQLOperationType:
            'operation' in definitionNode
              ? definitionNode.operation
              : undefined,
        } as any;
      }

      /**
       * End session when GraphQL returns UNAUTHENTICATED code in its
       * response body at:
       *  { errors: [{ extensions: { code: "UNAUTHENTICATED"} }] }
       */
      if (
        graphQLErrors?.some(
          (error) => error.extensions?.code === 'UNAUTHENTICATED'
        )
      ) {
        signOut();
      }
    });
  }, [signOut]);

  const apolloClient = useMemo(() => {
    return makeClient({
      requestHeaders: () => ({
        name: 'consumer-app',
        version: '0.1',
        app: 'mover',
        ...session,
      }),
      url: `${apiBaseUrl}/graphql`,
      links: session ? [logoutLink] : [],
      name: 'consumer-app',
      // eslint-disable-next-line no-underscore-dangle
      version: global.__VERSION_STRING__,
    });
  }, [session, apiBaseUrl, logoutLink]);

  useEffect(() => {
    const unsubscribe = apolloClient.onResetStore(async () => {
      datadogRum.addAction('apollo:storeReset', {
        uid: session?.uid,
      });
    });
    return unsubscribe;
  }, [apolloClient]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
