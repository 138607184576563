import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { getPromotions } from 'types/generated/getPromotions';

const DEALS_PROMOTION_FRAGMENT = gql`
  fragment dealsPromotionFragment on Promotion {
    id
    ownerId
    name
    categories
    code
    status
    image {
      url
      baseUrl
      id
    }
    redemption {
      code {
        type
        value
      }
      methods {
        value
      }
    }
    metadata {
      order
      claimType
      displayName
      description
      discount
      code
      fullTerms
      shortTerms
      url
      exclusive
      contactPreferenceRequired
      templates {
        contactPreferenceForm {
          template
        }
      }
      redemption {
        barcodeImage {
          url
        }
        description
      }
    }
    placements
    redemption {
      code {
        format
        type
        value
      }
      methods {
        description
        type
        value
      }
    }
  }
`;

const DEALS_ENTITY_FRAGMENT = gql`
  fragment dealsEntityFragment on Company {
    id
    name
    externalIds
    logo {
      url
      id
      baseUrl
    }
    metadata {
      displayName
      description
      shortDescription
    }
  }
`;

const CLAIMED_PROMOTION_FRAGMENT = gql`
  fragment claimedPromotionFragment on ClaimedPromotion {
    id
    claimedAt
    confirmationNumber

    redemption {
      code {
        value
        type
        format
      }
      methods {
        value
        type
        description
      }
    }
  }
`;

export const DEALS_PROMOTIONS_QUERY = gql`
  ${DEALS_PROMOTION_FRAGMENT}
  ${DEALS_ENTITY_FRAGMENT}
  ${CLAIMED_PROMOTION_FRAGMENT}

  query getPromotions {
    eligiblePromotions {
      eligiblePromotions {
        promotion {
          ...dealsPromotionFragment
        }
        validFor {
          __typename
          ...dealsEntityFragment
        }
      }
    }

    claimedPromotions {
      claimedPromotions {
        ...claimedPromotionFragment

        claimedFor {
          __typename
          ...dealsEntityFragment
        }

        promotion {
          ...dealsPromotionFragment
        }
      }
    }
  }
`;

export const usePromotionsQuery = (
  options?: QueryHookOptions<getPromotions>
) => {
  return useQuery<getPromotions>(DEALS_PROMOTIONS_QUERY, options);
};
