import { useQuery, useSuspenseQuery } from '@apollo/client';
import { useGetUserQuery } from '@updater/consumer-graph';
import { useAuth } from 'context/auth';
import { useMemo } from 'react';
import { getUserInfo, getUserInfoVariables } from 'types/generated/getUserInfo';
import { GetUserInfoQuery } from 'utils/queries';

export const useGetUserInfoQuery = () => {
  const { currentUserId } = useAuth();
  return useQuery<getUserInfo, getUserInfoVariables>(GetUserInfoQuery, {
    variables: {
      id: currentUserId,
    },
    skip: !currentUserId,
  });
};

export const useGetUserInfoSuspenseQuery = () => {
  const { currentUserId } = useAuth();
  return useSuspenseQuery<getUserInfo, getUserInfoVariables>(GetUserInfoQuery, {
    variables: {
      id: currentUserId,
    },
    skip: !currentUserId,
  });
};

export const useGetUserMoveDirection = () => {
  const { currentUserId } = useAuth();
  const { loading, data, error } = useGetUserQuery({
    variables: {
      id: currentUserId,
    },
    skip: !currentUserId,
  });

  return useMemo(() => {
    if (error) {
      return { error };
    }
    if (data) {
      return {
        moveDirection: data.getUser?.user?.currentMove?.direction,
        loading: false,
      };
    }
    return { loading };
  }, [loading, data, error]);
};
