import { useQuery } from '@apollo/client';
import { getSiteBranding } from 'flows/core/components/onboarding/common/queries';
import {
  siteBranding,
  siteBrandingVariables,
} from '../../../types/generated/siteBranding';

export const useSiteBranding = ({
  inviteToken,
}: { inviteToken?: string } = {}) =>
  useQuery<siteBranding, siteBrandingVariables>(getSiteBranding, {
    variables: {
      input: {
        subdomain: null,
        inviteToken,
      },
    },
  });
