import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { useAuth } from 'context/auth';

export function useQueryWhenAuthed<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> = Object.create(null)
) {
  const { isLoggedIn, isLoading } = useAuth();
  return useQuery<TData, TVariables>(query, {
    ...options,
    skip: isLoading || !isLoggedIn || options.skip,
  });
}
