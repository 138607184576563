import { useGrowthBook } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';

export const GrowthBookForcedFeatures: React.VFC<{
  features: Map<string, any>;
}> = ({ features }) => {
  const gb = useGrowthBook();
  useEffect(() => {
    gb?.setForcedFeatures(features);
    return () => gb?.setForcedFeatures(new Map([]));
  }, [gb, features]);
  return null;
};
