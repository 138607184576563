// https://github.com/Updater/frontend-core/tree/main/packages/ui-tracker for docs
import {
  Tracker,
  eventType,
  EventTypeMap,
  TrackEventInput,
} from '@updater/ui-tracker';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTracking } from 'react-tracking';
import { useServerProps } from './ServerProps';
import { useDeviceId } from './use-device-id';

const rootEventTypeMap: EventTypeMap = {
  consumerApp: {
    app_events: {
      loaded: eventType(),
    },
  },
};

const RootTrackerContext = React.createContext<
  Tracker<typeof rootEventTypeMap>
>(undefined!);

export const useRootTracker = () => useContext(RootTrackerContext);

export const RootTrackerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { env, eventStream } = useServerProps();
  const rootTracker = useMemo(
    () =>
      new Tracker({
        eventTypeMap: rootEventTypeMap,
        environment: env,
        destinations: {
          eventstream: {
            app: 'consumer-app',
            url: eventStream.url,
            batchUrl: eventStream.batchUrl,
            authorization: eventStream.token,
          },
        },
        channel: 'consumer',
        debug: true,
      }),
    [env, eventStream]
  );

  // clean up the tracker's set interval
  useEffect(() => rootTracker.destroy, [rootTracker]);

  const deviceId = useDeviceId();

  const { Track } = useTracking<TrackEventInput<unknown>>(
    {
      details: {
        // eslint-disable-next-line no-underscore-dangle
        app_version: global.__VERSION_STRING__,
        app_name: 'consumer-app',
        // camel for existing events 🐫
        deviceId,
      },
    },
    {
      dispatch: (event) => rootTracker.trackUntyped(event),
    }
  );
  return (
    <Track>
      <RootTrackerContext.Provider value={rootTracker}>
        {children}
      </RootTrackerContext.Provider>
    </Track>
  );
};
