import { useUserData } from 'flows/core/hooks/use-profile';
import { useTracking } from 'react-tracking';
import { useAuth } from 'context/auth';
import { BaseErrorBoundary } from './BaseErrorBoundary';
import { PropsWithChildren } from 'react';

export type ErrorBoundaryProps = {
  domain?: string;
  ErrorPage?: React.FC;
};
export const ErrorBoundary: React.FC<PropsWithChildren<ErrorBoundaryProps>> = ({
  children,
  domain: _domain,
  ErrorPage,
}) => {
  const domain = _domain || 'top_error_boundary';
  const userInfo = useUserData();
  const { logout } = useAuth();
  const { trackEvent } = useTracking({
    domain,
  });

  return (
    <BaseErrorBoundary
      trackEvent={trackEvent}
      ErrorPage={ErrorPage}
      logout={logout}
      uuid={userInfo?.user?.uuid || ''}
      domain={domain}
    >
      {children}
    </BaseErrorBoundary>
  );
};
