import styled from '@emotion/styled';
import Link from 'next/link';
import css from '@styled-system/css';

const sharedStyled = css({
  textDecoration: 'none',
  fontWeight: 'semiBold',
  '&:hover': {
    color: 'link',
    textDecoration: 'underline',
  },
  '&:visited': {
    '&:hover': {
      color: 'hover',
    },
  },
});

export const StyledMenuLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => `${theme.fontSizes.xxxl}px`};
  &:visited {
    color: ${({ theme }) => theme.colors.white};
  }

  ${sharedStyled}
`;

export const StyledHeaderLink = styled(Link)`
  color: ${({ theme }) => theme.colors.link};
  font-size: ${({ theme }) => `${theme.fontSizes.l}px`};
  &:visited {
    color: ${({ theme }) => theme.colors.link};
  }
  ${sharedStyled}
`;
