import { useMutation, useSuspenseQuery } from '@apollo/client';
import { useAuth } from 'context/auth';
import {
  UpdateUserInput,
  VerifyPinUserFieldInput,
} from 'types/generated/globalTypes';
import {
  UpdateMoveDate,
  UpdateMoveDateVariables,
} from 'types/generated/UpdateMoveDate';
import { updateUser, updateUserVariables } from 'types/generated/updateUser';
import { verifyPin, verifyPinVariables } from 'types/generated/verifyPin';
import {
  GetSubscriptionPreferencesQuery,
  GetUserInfoQuery,
  UpdateMoveDateMutation,
  UpdateUserMutation,
  VerifyPinMutation,
} from 'utils/queries';
import {
  useGetUserInfoQuery,
  useGetUserInfoSuspenseQuery,
} from './use-get-user-info-query';
import {
  getSubscriptionPreferences,
  getSubscriptionPreferencesVariables,
} from 'types/generated/getSubscriptionPreferences';

export const useAddress = () => {
  const { data, error, loading } = useGetUserInfoQuery();
  const currentMove = data?.getUser?.user?.currentMove;
  return { currentMove, error, loading };
};

export const useUserData = () => {
  const { data, ...rest } = useGetUserInfoQuery();
  return { user: data?.getUser?.user, ...rest };
};

export const useUserDataSuspense = () => {
  const { data, ...rest } = useGetUserInfoSuspenseQuery();
  return { user: data?.getUser?.user, ...rest };
};

export const useUpdateUser = () => {
  const { currentUserId } = useAuth();
  const [userDataMutation, updateUserDataResult] = useMutation<
    updateUser,
    updateUserVariables
  >(UpdateUserMutation, {
    refetchQueries: [GetUserInfoQuery],
  });

  const updateUserData = (userData: UpdateUserInput) =>
    userDataMutation({
      variables: {
        input: {
          id: currentUserId,
          ...userData,
        },
      },
      // Catch error to avoid formik from detecting it
    }).catch(() => {});

  return { updateUserData, ...updateUserDataResult };
};

export const useVerifyPin = (policies: object) => {
  const [verifyPinMutation, verificationResult] = useMutation<
    verifyPin,
    verifyPinVariables
  >(VerifyPinMutation, {
    errorPolicy: 'all',
    ...policies,
  });

  const verifyPinCode = (data: {
    userField: VerifyPinUserFieldInput;
    pin: string;
  }) =>
    verifyPinMutation({
      variables: {
        input: { userField: data.userField, pin: data.pin },
      },
    }).catch(() => {});

  return { verifyPinCode, ...verificationResult };
};

export const useUpdateMoveDate = () => {
  const [updateMoveDateMutation, updateMoveDateResult] = useMutation<
    UpdateMoveDate,
    UpdateMoveDateVariables
  >(UpdateMoveDateMutation);

  const updateMoveDate = (data: { date: string; id: string }) =>
    updateMoveDateMutation({
      variables: {
        input: {
          ...data,
        },
      },
    }).catch(() => {});

  return { updateMoveDate, ...updateMoveDateResult };
};

export const useNotificationData = () => {
  const { currentUserUUID } = useAuth();
  return useSuspenseQuery<
    getSubscriptionPreferences,
    getSubscriptionPreferencesVariables
  >(GetSubscriptionPreferencesQuery, {
    variables: {
      input: {
        userUuid: currentUserUUID,
      },
    },
    skip: !currentUserUUID,
  });
};
