import { INPUT_VALIDATORS } from '@updater/ui-informant';
import { useUserData } from 'flows/core/hooks/use-profile';
import { useSWRWhenAuthed } from 'utils/use-swr-when-authed';
import { useGetDivisionId } from './use-get-divisionId';

interface DivisionData {
  number_of_units: number;
  phone: string;
  email: string;
  address: {
    city: string;
    street: string;
    state: string;
    zip: string;
  };
}

const isPropertyAddressValid = (address?: {
  city?: string;
  street?: string;
  state?: string;
  zip?: string;
}) => {
  let isValid = true;

  if (address) {
    for (const value of Object.values(address)) {
      if (!!!value) {
        isValid = false;
      }
    }
  }

  return isValid;
};

const isValidValue = (
  value: string,
  extraRegExpValid?: string | RegExp
): boolean => {
  const extraValidationValid = extraRegExpValid
    ? !!`${value}`.match(extraRegExpValid)
    : true;
  return extraValidationValid;
};

const isPhoneNumberValid = (phoneNum: string) => {
  const cleanPhone = phoneNum.replaceAll(/[^\dx]/g, '').split('x')[0];
  // optionally starting with a 1 then a 10 digit number, 1 isn't a valid area code start.
  const phoneRegex = /^1?[2-9]\d{2}[2-9]\d{6}$/;
  let uniqueDigits = new Set(cleanPhone.split(''));

  // if number has proceeding 1 then dont use 1 in unique test
  if (cleanPhone.length > 10 && cleanPhone.charAt(0) === '1') {
    uniqueDigits = new Set(cleanPhone.slice(1).split(''));
  }

  return uniqueDigits.size > 1 && isValidValue(cleanPhone, phoneRegex);
};

/**
   * Requirements to show contact from data team: 
   * Has Property Name 
     Has Unit Count 
    Has Full Address 
    Has Email  (requires at least 1 character domain remain after removing spaces and “tbd”)
    Has Phone  (requires 10 digits after removing prefix, leading zeros, and tpn that are primarily duplicate values)
   * 
   */
export const validatePropertyContact = (data?: DivisionData): boolean => {
  const numberOfUnits = data?.number_of_units || 0;
  const phone = data?.phone || '';
  const email = data?.email || '';
  const { street, city, state, zip } = data?.address || {};
  const address = { street, city, state, zip };
  const isAddressValid = isPropertyAddressValid(address);
  // we have a lot of bogus emails with tbd@tbd.com in our system - we want to check for this
  const tbdRegex = /@tbd/i;

  const phoneValid = isPhoneNumberValid(phone);

  const emailValid =
    isValidValue(email, INPUT_VALIDATORS.EMAIL) && !tbdRegex.test(email);

  const isValid =
    numberOfUnits > 0 && emailValid && isAddressValid && phoneValid;
  return isValid;
};
export const useIsPropertyValid = (): boolean => {
  const { user } = useUserData();
  const { divisionId, loading, error } = useGetDivisionId();

  const { data } = useSWRWhenAuthed(
    divisionId ? `/v2/divisions/${divisionId}` : null
  );

  if (loading || error || !user || !data) {
    return false;
  }

  const isMoveIn = user?.currentMove?.direction === 'move_in';
  const isPropertyContactValid = validatePropertyContact(data);

  return isMoveIn && isPropertyContactValid;
};
