import { FC, PropsWithChildren } from 'react';

import { AuthProvider } from '@updater/auth';
import { useServerProps } from 'utils/ServerProps';

export const UpdaterAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { apiBaseUrl } = useServerProps();

  return (
    <AuthProvider
      url={apiBaseUrl}
      headers={{ app: 'mover', name: 'consumer-app' }}
      children={children}
    />
  );
};
